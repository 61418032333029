import React, { useEffect, useState } from "react";
import api from "app/api";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";

const AuthorizedImage = ({ path, width, height, zoomLevel, onClick }) => {
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(`/serviceapi/writeImageToClient?location=${path}`, {
          responseType: "arraybuffer",
        });

        const base64Data = Buffer.from(response.data, "binary").toString("base64");
        const imageDataUrl = `data:${response.headers["content-type"]};base64,${base64Data}`;
        setIsLoading(false);
        setImageData(imageDataUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImage();
  }, [path]);

  const imageStyle = {
    width: width || null,
    height: height || null,
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: 100,
    minHeight: 100,
    transform: `scale(${zoomLevel})`,
  };
  if (isLoading)
  return (
    <div
      style={{
        textAlign: "center",
        paddingTop: 50,
        paddingBottom: 50,
      }}
    >
      <CircularProgress color="secondary" />
    </div>
  );
  return imageData ? <img src={imageData} alt="" style={imageStyle} onClick={onClick} /> : null;
};

export default AuthorizedImage;
