import React, { useEffect, useState } from "react";
import Form from "component/form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addMachine, getMachines, updateMachine, getMachineDepartments } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import { Typography, Button, CircularProgress, Stack, TextField, Tooltip, Autocomplete, Checkbox } from "@mui/material";
import { isYear } from "utils/utilFunctions";

const AddEditMachine = (props) => {
  // const { id } = useParams();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    serialNumber: "",
    manufacturingYear: "",
    maintenanceCode: "",
    assetNumber: "",
    model: "",
    machineType: "",
    criticalMachine: false,
    location: "",
  });
  const [errors, setErrors] = useState({});
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_FINANCE)) setIsEditDisabled(true);
    dispatch(getMachineDepartments());
  }, [dispatch]);

  useEffect(() => {
    if (props && props.initialFormData) setFormData(props.initialFormData);
  }, [props.initialFormData]);
  const { machinesData, appConstants, machineDepartments, isLoading } = useSelector((state) => state.service);

  const validations = {
    serialNumber: true,
    // manufacturingYear: true,
    location: true,
    model: true,
    machineType: false,
    maintenanceCode: true,
    assetNumber: false,
  };

  const handleSubmit = () => {
    if (formData.manufacturingYear && !isYear(formData.manufacturingYear)) return toast.warn("Please enter a valid year!");
    if (!formData.serialNumber || !formData.model || !formData.maintenanceCode || !formData.location) {
      setErrors({
        serialNumber: formData.serialNumber ? "" : "Please enter serial number!",
        model: formData.model ? "" : "Please enter model!",
        maintenanceCode: formData.maintenanceCode ? "" : "Please enter maintenance code!",
        location: formData.location ? "" : "Please enter location!",
      });
      return toast.warn("Please enter required fields!");
    }
    const selectedDepartment = machineDepartments.find((item) => item.name === formData.location);
    formData.modelId = appConstants.machineModels.find((item) => item.model == formData.model).id;
    if (formData.id) {
      formData.machineId = Number(formData.id);
      formData.departmentId = selectedDepartment.id;
      formData.department = selectedDepartment.name;
      delete formData.amcExpiryDate;
      delete formData.warrantyExpiryDate;
    } else {
      formData.departmentId = selectedDepartment.id;
      delete formData.manufacturer;
    }
    const maintenanceCodeExists = machinesData.some((machine) => machine.maintenanceCode == formData.maintenanceCode);
    if (maintenanceCodeExists && !formData.id) return toast.error("Maintenance code exists!");

    const serialNumberExists = machinesData.some((item) => item.serialNumber === formData.serialNumber);
    if (serialNumberExists && !formData.id) return toast.error("This data already exists!");
    formData.manufacturingYear = Number(formData.manufacturingYear);
    if (formData.id) submitForm(updateMachine, formData);
    else submitForm(addMachine, formData);
  };

  const submitForm = (action, formData) => {
    dispatch(action(formData)).then((machineRes) => {
      if (machineRes && machineRes.error) toast.error("Something went wrong!");
      if (machineRes && machineRes.payload.data && machineRes.payload.status === 200) {
        if (!formData.machineId) navigate(`/${Links.EDIT_MACHINE}/` + machineRes.payload.data.id, { replace: true });
        dispatch(getMachines()).then((res) => {
          toast.success("Machine " + (formData.machineId ? "updated" : "added") + " successfully!");
          props.setEnableEditing(false);
        });
      } else toast.error("Something went wrong!");
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <div>
      <Stack direction={"row"} gap={2}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <TextField
            disabled={isEditDisabled}
            label="Serial number"
            value={formData.serialNumber}
            onChange={(event) => setFormData({ ...formData, serialNumber: event.target.value })}
            error={errors && errors.serialNumber && !formData.serialNumber}
            helperText={errors.serialNumber}
          />
          <TextField
            disabled={isEditDisabled}
            label="Manufacturing Year"
            value={formData.manufacturingYear}
            onChange={(event) => setFormData({ ...formData, manufacturingYear: event.target.value })}
          />
          <TextField
            disabled={isEditDisabled}
            label="Maintenance Code"
            value={formData.maintenanceCode}
            onChange={(event) => setFormData({ ...formData, maintenanceCode: event.target.value })}
            error={errors && errors.maintenanceCode && !formData.maintenanceCode}
            helperText={errors.maintenanceCode}
            // inputProps={{ maxLength: 4000, helperText: "ADEQ" }}
          />
          <TextField
            label="Asset Number"
            value={formData.assetNumber}
            onChange={(event) => setFormData({ ...formData, assetNumber: event.target.value })}
          />
          <Autocomplete
            disabled={isEditDisabled}
            disablePortal
            id="combo-box-demo"
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                model: newValue.model,
              })
            }
            isOptionEqualToValue={(option, value) => option.model == value.model}
            getOptionLabel={(option) => option.model}
            value={
              Object.keys(appConstants).length ? appConstants?.machineModels.find((item) => item.model === formData.model) || null : null
            }
            options={
              Object.keys(appConstants).length ? [...appConstants?.machineModels].sort((a, b) => a.model.localeCompare(b.model)) : []
            }
            sx={{ minWidth: 250, maxWidth: 600 }}
            renderInput={(params) => (
              <TextField {...params} label="Model" error={errors && errors.model && !formData.model} helperText={errors.model} />
            )}
          />
          <Autocomplete
            disabled={isEditDisabled}
            disablePortal
            id="combo-box-demo"
            onChange={(event, newValue, reason) =>
              setFormData({
                ...formData,
                machineType: reason === "clear" ? "" : newValue,
              })
            }
            value={formData.machineType || null}
            options={Object.keys(appConstants).length ? [...appConstants?.machineType].sort((a, b) => a.localeCompare(b)) : []}
            sx={{ minWidth: 250, maxWidth: 600 }}
            renderInput={(params) => <TextField {...params} label="Machine Type" />}
          />
          <Autocomplete
            disabled={isEditDisabled}
            disablePortal
            id="combo-box-demo"
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                location: newValue.name,
              })
            }
            isOptionEqualToValue={(option, value) => option.name == value.name}
            getOptionLabel={(option) => option.name}
            value={machineDepartments.find((item) => item.name === formData.location) || null}
            options={
              Object.keys(appConstants).length
                ? machineDepartments
                    .map((item) => ({ ...item, value: item.name, label: item.name }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                : []
            }
            sx={{ minWidth: 250, maxWidth: 600 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                error={errors && errors.location && !formData.location}
                helperText={errors.location}
              />
            )}
          />
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              disabled={isEditDisabled}
              checked={formData.criticalMachine}
              onChange={(event) =>
                setFormData({
                  ...formData,
                  criticalMachine: event.target.checked,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>
              {formData.criticalMachine
                ? "Critical Machine (Uncheck the box to disable critical machine)"
                : "Critical Machine (Check the box to enable critical machine)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Tooltip title="Save">
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <ToastContainer />
    </div>
  );
};

export default AddEditMachine;
