import api from "app/api";
import multiInstance from "app/multiPartApi";

const addUser = async (payload) => {
  const response = await api.post("/serviceapi/createUser", payload);
  return response;
};

const updateUser = async (payload) => {
  const response = await api.post("/serviceapi/updateUser", payload);
  return response;
};

const getAllUsers = async () => {
  const response = await api.get("/serviceapi/getAllUsers");
  return response;
};

const getAllVendors = async () => {
  const response = await api.get("/serviceapi/getAllVendors");
  return response;
};

const updateVendor = async (payload) => {
  const response = await api.post("/serviceapi/updateVendor", payload);
  return response;
};

const createVendor = async (payload) => {
  const response = await api.post("/serviceapi/createVendor", payload);
  return response;
};

const updateVendorContact = async (payload) => {
  const response = await api.post("/serviceapi/updateVendorContact", payload);
  return response;
};

const Service = {
  getAllUsers,
  addUser,
  updateUser,
  getAllVendors,
  updateVendor,
  createVendor,
  updateVendorContact,
};

export default Service;
