import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Stack, List, Tooltip, Typography, CircularProgress, CardContent } from "@mui/material";
import moment from "moment";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { addImageOrVideoToServiceTicket, readAsyncStorageValues, readServiceTicketMessages } from "features/service/service.slice";
import { selectCurrentToken } from "features/auth/auth.slice";
import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedPDF from "component/AuthorizedPDF";
import api from "app/api";
import AuthorizedVideo from "component/AuthorizedVideo";
import videoImage from "../../images/Video.png";

const Gallery = ({ ticketId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { serviceTicketMessages, appConstants, isLoading, serviceTicketById } = useSelector((state) => state.service);
  let token = useSelector(selectCurrentToken);
  const [selectedFile, setSelectedFile] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const fileInputRef = useRef(null);
  const [showDialogView, setShowDialogView] = useState(false);
  token = token.access_token ? token.access_token : token;
  const [zoomLevel, setZoomLevel] = useState(0.8);
  const [imageZoomLevel, setImageZoomLevel] = useState(1);
  const [currentFilePathIndex, setCurrentFilePathIndex] = useState(null);

  useEffect(() => {
    dispatch(readServiceTicketMessages(ticketId));
  }, [dispatch, ticketId]);

  useEffect(() => {
    if (serviceTicketMessages) {
      const nextImageVideo = serviceTicketMessages.filter((message) => message.type === "image/jpg" || message.type === "video/mp4");
      if (currentFilePathIndex >= nextImageVideo.length) {
        setFilePath(nextImageVideo[0]);
        setCurrentFilePathIndex(0);
      } else if (currentFilePathIndex < 0) {
        const lastIndex = nextImageVideo.length - 1;
        setFilePath(nextImageVideo[lastIndex]);
        setCurrentFilePathIndex(lastIndex);
      } else {
        setFilePath(nextImageVideo[currentFilePathIndex]);
        setCurrentFilePathIndex(currentFilePathIndex);
      }
    }
  }, [serviceTicketMessages, currentFilePathIndex]);

  const handleZoomIn = () => {
    if (filePath && filePath.type === "pdf") {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    } else if (filePath && filePath.type === "image/jpg") {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (filePath && filePath.type === "pdf") {
      setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    } else if (filePath && filePath.type === "image/jpg") {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    }
  };

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(readServiceTicketMessages(ticketId));
  }, [dispatch]);

  useEffect(() => {}, [appConstants, serviceTicketMessages]);

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowDialog(true);
  };

  const handleViewFile = (file, index) => {
    setShowDialogView(true);
    setFilePath(file);
    // setCurrentFilePathIndex(index);
  };

  const handleNext = () => {
    setFilePath(serviceTicketMessages[currentFilePathIndex + 1]);
    setCurrentFilePathIndex(currentFilePathIndex + 1);
  };

  const handlePrevious = () => {
    setFilePath(serviceTicketMessages[currentFilePathIndex - 1]);
    setCurrentFilePathIndex(currentFilePathIndex - 1);
  };

  const handleFileUpload = async () => {
    // formData.append("file", selectedFile);
    const formData = new FormData();
    formData.append("image", selectedFile);
    selectedFile.type == "application/pdf"
      ? formData.append("type", "pdf")
      : selectedFile.type.includes("video")
      ? formData.append("type", "video/mp4")
      : formData.append("type", "image/jpg");
    formData.append("serviceTicketId", "" + ticketId);

    try {
      await dispatch(addImageOrVideoToServiceTicket(formData));
      toast.success("File uploaded successfully!");
      setSelectedFile(null);
      setShowDialog(false);
      dispatch(readServiceTicketMessages(ticketId));
    } catch (error) {
      toast.error("Please try after some time!");
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const handleCloseDialogView = () => {
    setShowDialogView(false);
    setZoomLevel(0.8);
    setImageZoomLevel(1);
    fileInputRef.current.value = null;
  };

  const handleDownload = async () => {
    try {
      const response = await api.get(`/serviceapi/writeImageToClient?location=${filePath.message}`, {
        responseType: "blob",
      });

      const fileExtension = filePath.message.split(".").pop().toLowerCase();
      let mimeType;
      if (fileExtension === "mp4") {
        mimeType = "video/mp4";
      } else if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
        mimeType = "image/jpeg";
      } else {
        console.error("Unsupported file type.");
        return;
      }

      const fileUrl = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = fileUrl;
      const fileName = filePath.message.split("/").pop();
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.log(error);
    }
  };

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" fontWeight={600} mb={1}>
          Files
        </Typography>
        <input type="file" style={{ display: "none" }} onChange={handleFileSelect} id="fileInput" ref={fileInputRef} />
        {serviceTicketById?.status != appConstants?.allTypes?.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED ? (
          <Tooltip title="Add file">
            <Button
              sx={{ height: "self" }}
              variant="contained"
              color="primary"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Add
            </Button>
          </Tooltip>
        ) : null}
      </Stack>
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle>Selected File</DialogTitle>
        <DialogContent>
          {/* File preview */}
          {selectedFile ? (
            selectedFile.type.includes("image") ? (
              <img src={URL.createObjectURL(selectedFile)} alt="Selected File" style={{ maxWidth: "100%", maxHeight: "500px" }} />
            ) : selectedFile.type.includes("pdf") ? (
              <embed src={URL.createObjectURL(selectedFile)} width="800" height="700" />
            ) : selectedFile.type.includes("video") ? (
              <video controls style={{ width: "100%" }} height="auto">
                <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <span>Selected File is Incorrect</span>
            )
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {selectedFile ? (
            <Button variant="contained" onClick={handleFileUpload}>
              Upload
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      <ToastContainer />
      <Dialog
        open={showDialogView}
        onClose={handleCloseDialogView}
        PaperProps={{
          style: {
            maxWidth: "1100px",
            minWidth: "500px",
          },
        }}
      >
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <DialogTitle style={{ flexGrow: 1 }}>Selected File</DialogTitle>
            {filePath && filePath.type == "video/mp4" ? null : (
              <div style={{ display: "grid", gridAutoFlow: "column", columnGap: "10px", paddingRight: 10 }}>
                <span
                  onClick={handleZoomIn}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    borderRadius: "50%",
                    background: "lightgray",
                    width: "30px",
                    height: "30px",
                    lineHeight: "30px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  +
                </span>
                <span
                  onClick={handleZoomOut}
                  style={{
                    cursor: "pointer",
                    display: "inline-block",
                    borderRadius: "50%",
                    background: "lightgray",
                    width: "30px",
                    height: "30px",
                    lineHeight: "30px",
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                >
                  &#8722;
                </span>
              </div>
            )}
          </div>
        </>

        <DialogContent>
          {/* File preview */}
          {filePath && filePath.type == "pdf" ? (
            <AuthorizedPDF path={filePath.message} scale={zoomLevel} showPages={true} />
          ) : filePath && filePath.type == "image/jpg" ? (
            <AuthorizedImage path={filePath.message} zoomLevel={imageZoomLevel} />
          ) : filePath && filePath.type == "video/mp4" ? (
            <AuthorizedVideo path={filePath.message} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Stack direction="row" alignItems={"center"} justifyContent="space-between" sx={{ mt: 5, height: "100%" }}>
            <Button onClick={handlePrevious}>
              <ChevronLeftIcon />
            </Button>
            <Button onClick={handleNext}>
              <ChevronRightIcon />
            </Button>
          </Stack>
        </DialogActions>
        <DialogActions>
          <Button onClick={handleCloseDialogView}>Cancel</Button>
          <Button onClick={handleDownload} sx={{ height: "self" }}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Stack mb={1}>
        <List
          sx={{
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          {Object.keys(appConstants).length &&
            serviceTicketMessages.map((note, index) => (
              <div key={index}>
                {note.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      display: "flex",
                    }}
                    onClick={() => handleViewFile(note, index)}
                  >
                    <AuthorizedImage path={note.message} width={100} height={100} />
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : note.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      background: "rgb(247,247,247)",
                      display: "flex",
                      background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    }}
                    onClick={() => handleViewFile(note)}
                  >
                    <div style={{ width: 100, height: 100 }}>
                      <AuthorizedPDF path={note.message} scale={0.135} />
                    </div>
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : note.type === "video/mp4" ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      background: "rgb(247,247,247)",
                      display: "flex",
                      background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    }}
                    onClick={() => handleViewFile(note)}
                  >
                    <div style={{ width: 100, height: 100 }}>
                      {/* <AuthorizedVideo path={note.message} width={50} height={50} /> */}
                      <img src={videoImage} width={100} height={100} />
                    </div>
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : null}
              </div>
            ))}
        </List>
      </Stack>
    </div>
  );
};

export default Gallery;
