import React from "react";
import { Box, Stack, Modal, Typography, CircularProgress, IconButton, Autocomplete, TextField, Paper } from "@mui/material";

const DashBoard = () => {
  return (
    <div>
      <Stack sx={{ ml: 3, mt: 0 }}>
        <iframe
          class="responsive-iframe"
          src="https://kibana.techenablesme.com/app/dashboards#/view/741eeff0-26fd-11ee-a92e-5bbbb2a79026?embed=true&_g=(refreshInterval:(pause:!t,value:60000),time:(from:now-6y,to:now))"
          height="600"
        ></iframe>
      </Stack>
    </div>
  );
};

// Need further research
// Refused to execute inline script because it violates the following Content Security Policy directive: "script-src 'self'". Either the 'unsafe-inline' keyword,
// a hash ('sha256-P5polb1UreUSOe5V/Pv7tc+yeZuJXiOi/3fqhGsU7BE='), or a nonce ('nonce-...') is required to enable inline execution.

export default DashBoard;
