import React, { useEffect, useState } from "react";
import api from "app/api";
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";

const AuthorizedVideo = ({ path, width, height, zoomLevel, onClick }) => {
  const [videoUrl, setVideoUrl] = useState(null);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        const response = await api.get(`/serviceapi/writeImageToClient?location=${path}`, {
          responseType: "blob",
        });

        const videoUrl = URL.createObjectURL(response.data);
        setVideoUrl(videoUrl);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideo();
  }, [path]);

  const VideoStyle = {
    width: 900,
    height: 500,
    display: "flex",
  };

  return videoUrl ? (
    <video controls style={VideoStyle} onClick={onClick}>
      <source src={videoUrl} type="video/mp4" />
      {/* Add additional <source> elements for other video formats if needed */}
      Your browser does not support the video tag.
    </video>
  ) : (
    <Stack alignItems="center" sx={{ my: 5 }}>
      <CircularProgress size={30} color="secondary" />
    </Stack>
  );
};

export default AuthorizedVideo;
