export const Links = {
  LOGIN: "login",
  DASHBOARD: "dashboard",
  SERVICE_DASHBOARD: "service-dashboard",
  HOURS: "hours",
  TIME_SPENT_BY_SERVICE_TICKETS: "service-tickets-hours",
  REPORTS: "reports",
  VIEW_REPORT:"view-report",
  MAINTENANCE_REPORT:"/maintenance-report",
  ALL_SERVICE_TICKETS: "service-tickets",
  ASSIGN_TECHNICIAN_SERVICE_TICKETS: "service-tickets/assign-technician",
  ACTION_REQUIRED_SERVICE_TICKETS: "service-tickets/action-required",
  PENDING_SERVICE_TICKETS: "service-tickets/pending",
  SERVICE_TICKET: "service-ticket",
  CREATE_SERVICE_TICKET: "service-tickets/create",
  UPDATE_SERVICE_TICKET: "service-tickets/edit",
  ASSETS: "machines",
  SCHEDULING: "scheduling",
  INVENTORY: "inventory",
  ADD_SPARE_PART: "spare-part/add",
  EDIT_SPARE_PART: "spare-part/edit",
  PLANT_OVERVIEW: "plantoverview",
  BREAKDOWN: "breakdown",
  ADD_MACHINE: "machine/add",
  EDIT_MACHINE: "machine/edit",
  MACHINE_SERVICE_TICKET: "machine/service-ticket",
  // INSPECTION: "inspection",
  TEMPLATES: "templates",
  CREATE_TEMPLATE: "template/create",
  EDIT_TEMPLATE: "template/edit",
  USER: "users",
  VENDORS: "vendors",
  ADD_VENDOR: "vendor/add",
  EDIT_VENDOR: "vendor/edit",
  ADD_USER: "user/add",
  EDIT_USER: "user/edit",
  REPORTS: "reports",
  LOCATIONS: "locations",
  ADD_LOCATION: "location/add",
  EDIT_LOCATION: "location/edit",
  MACHINE_MODELS: "machine-models",
  MACHINE_MODEL: "machine-model",
  EDIT_MACHINE_MODELS: "machine-models",
  CREATE_MACHINE_MODELS: "machine-models/create",
};
