import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Paper, Stack, useTheme, Box, List, ListItemText, ListItemButton, CircularProgress } from "@mui/material";

import { getCompletedReportPdf } from "features/service/service.slice";
import api from "app/api";
import AuthorizedPDF from "component/AuthorizedPDF";

const ViewReports = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { id } = useParams();
  const [selectedPdfIndex, setSelectedPdfIndex] = useState(0);
  const { completedReportPdf, isLoading } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getCompletedReportPdf(id));
  }, [dispatch, id]);

  const handleDownload = async (pdfPath) => {
    try {
      const response = await api.get(`/serviceapi/writeImageToClient?location=${pdfPath}`, {
        responseType: "blob",
      });
      const href = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = href;
      const pdfFileName = pdfPath.split("/").pop();
      link.setAttribute("download", pdfFileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Stack flexDirection={"row"} pr={2}>
      {isLoading ? (
        <Box>
          <div
            style={{
              textAlign: "center",
              paddingTop: 200,
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        </Box>
      ) : (
        <>
          <List
            component="nav"
            sx={{
              width: 400,
              borderRight: 1,
              borderColor: "divider",
              backgroundColor: "white",
              marginLeft: "30px",
              height: 910,
              overflowY: "scroll",
            }}
          >
            {completedReportPdf?.map((pdfPath, index) => (
              <ListItemButton
                key={index}
                onClick={() => setSelectedPdfIndex(index)}
                selected={selectedPdfIndex === index}
                sx={{
                  boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
                  borderRadius: "8px",
                  marginBottom: "10px",
                  backgroundColor: selectedPdfIndex === index ? theme.palette.primary.main : "#f5f5f5",
                }}
              >
                <ListItemText primary={pdfPath.split("/").pop()} sx={{ color: "black",wordBreak: "break-all", }} />
              </ListItemButton>
            ))}
          </List>

          <Box ml={10}>
            <Paper elevation={8} sx={{ width: 750, padding: 1 }}>
              <AuthorizedPDF path={completedReportPdf[selectedPdfIndex]} scale={1} showPages={true} />
              <div style={{ textAlign: "center" }}>
                <Button
                  onClick={() => handleDownload(completedReportPdf[selectedPdfIndex])}
                  color="primary"
                  variant="contained"
                  sx={{ width: "200px" }}
                >
                  Download
                </Button>
              </div>
            </Paper>
          </Box>
        </>
      )}
    </Stack>
  );
};

export default ViewReports;
