import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedPDF from "component/AuthorizedPDF";
import api from "app/api";
import AuthorizedVideo from "component/AuthorizedVideo";

const ArtifactsDialogBox = ({ showDialogView = false, data = [], setShowDialogView, selectedInitialIndex = 0 }) => {
  const { appConstants } = useSelector((state) => state.service);
  const [zoomLevel, setZoomLevel] = useState(0.8);
  const [imageZoomLevel, setImageZoomLevel] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(selectedInitialIndex);
  const handleCloseDialogView = () => setShowDialogView(false);
  const handleZoomIn = () => {
    if (data[currentIndex] && data[currentIndex].type === appConstants.allTypes.TYPE_OF_CONTENT.PDF) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    } else if (data[currentIndex] && data[currentIndex].type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (data[currentIndex] && data[currentIndex].type === appConstants.allTypes.TYPE_OF_CONTENT.PDF) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    } else if (data[currentIndex] && data[currentIndex].type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    }
  };

  const handlePrevious = () => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : data.length - 1);
  const handleNext = () => setCurrentIndex(currentIndex === data.length - 1 ? 0 : currentIndex + 1);

  const handleDownload = async () => {
    try {
      const response = await api.get(`/serviceapi/writeImageToClient?location=${data[currentIndex].url}`, {
        responseType: "blob",
      });

      const fileExtension = data[currentIndex].url.split(".").pop().toLowerCase();
      let mimeType;
      if (fileExtension === "mp4") {
        mimeType = "video/mp4";
      } else if (fileExtension === "png" || fileExtension === "jpg" || fileExtension === "jpeg") {
        mimeType = "image/jpeg";
      } else {
        console.error("Unsupported file type.");
        return;
      }

      const fileUrl = URL.createObjectURL(response.data);

      const link = document.createElement("a");
      link.href = fileUrl;
      const fileName = data[currentIndex].url.split("/").pop();
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Dialog
      open={showDialogView}
      onClose={handleCloseDialogView}
      PaperProps={{
        style: {
          maxWidth: "1100px",
          minWidth: "500px",
        },
      }}
    >
      <>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
          <DialogTitle style={{ flexGrow: 1 }}>Selected File</DialogTitle>
          {data.length && data[currentIndex] && data[currentIndex].type == "video/mp4" ? null : (
            <div style={{ display: "grid", gridAutoFlow: "column", columnGap: "10px", paddingRight: 10 }}>
              <span
                onClick={handleZoomIn}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  borderRadius: "50%",
                  background: "lightgray",
                  width: "30px",
                  height: "30px",
                  lineHeight: "30px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                +
              </span>
              <span
                onClick={handleZoomOut}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  borderRadius: "50%",
                  background: "lightgray",
                  width: "30px",
                  height: "30px",
                  lineHeight: "30px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                &#8722;
              </span>
            </div>
          )}
        </div>
      </>
      <DialogContent>
        {/* File preview */}
        {data[currentIndex] && data[currentIndex].type == appConstants.allTypes.TYPE_OF_CONTENT.PDF ? (
          <AuthorizedPDF path={data[currentIndex].url} scale={zoomLevel} showPages={true} />
        ) : data[currentIndex] && data[currentIndex].type == appConstants.allTypes.TYPE_OF_CONTENT.IMAGE ? (
          <AuthorizedImage path={data[currentIndex].url} zoomLevel={imageZoomLevel} />
        ) : data[currentIndex] && data[currentIndex].type == "video/mp4" ? (
          <AuthorizedVideo path={data[currentIndex].url} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" alignItems={"center"} justifyContent="space-between" sx={{ mt: 5, height: "100%" }}>
          <Button onClick={handlePrevious}>
            <ChevronLeftIcon />
          </Button>
          <Button onClick={handleNext}>
            <ChevronRightIcon />
          </Button>
        </Stack>
      </DialogActions>
      <DialogActions>
        <Button onClick={handleCloseDialogView}>Cancel</Button>
        <Button onClick={handleDownload} sx={{ height: "self" }}>
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ArtifactsDialogBox;
