import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Typography, Pagination, PaginationItem, Stack, Paper, Box } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";

const DataGridTable = ({
  data = [],
  columnAttributes = [],
  rowId = "id",
  checkboxSelection = false,
  onSelectRow,
  loading = false,
  enableExport = false,
  exportFileName = "Airman",
}) => {
  const PAGE_SIZE = 12;

  const { timespentByServicetTicket } = useSelector((state) => state.service);
  //   const [data, setData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: PAGE_SIZE,
    page: 0,
  });

  const CustomPagination = () => {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Stack flexDirection="row" gap={1} alignItems="center">
        <Pagination
          color="secondary"
          variant="outlined"
          shape="rounded"
          page={page + 1}
          count={pageCount}
          boundaryCount={1}
          siblingCount={2}
          showFirstButton
          showLastButton
          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
        <Typography pr={1}>({data.length} items)</Typography>
      </Stack>
    );
  };
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          csvOptions={{
            fileName: exportFileName,
            utf8WithBom: true,
          }}
        />
      </GridToolbarContainer>
    );
  }
  const CustomNoRowsOverlay = () => (
    <Box sx={{ height: 6 }}>
      <Typography>No items.</Typography>
    </Box>
  );
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);

  return (
    <Box>
      <Paper>
        <div class="dataGridTable" style={data.length <= 1 ? { height: 200 } : data.length > 1 && data.length < 7 ? { height: 400 } : {}}>
          <DataGrid
            checkboxSelection={checkboxSelection}
            rows={data && data.length ? data : []}
            getRowId={(row) => row[rowId]}
            slots={{
              pagination: CustomPagination,
              noRowsOverlay: CustomNoRowsOverlay,
              noResultsOverlay: CustomNoRowsOverlay,
              toolbar: enableExport ? CustomToolbar : null,
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[PAGE_SIZE]}
            disableRowSelectionOnClick
            columns={columnAttributes}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              onSelectRow(newRowSelectionModel);
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            rowHeight={50}
            loading={loading}
          />
        </div>
      </Paper>
    </Box>
  );
};

export default DataGridTable;
