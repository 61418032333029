import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { updateMachineModel, readAsyncStorageValues } from "features/service/service.slice";
import { DialogTitle, Stack, Paper, Typography, DialogActions, Button, Dialog, Tooltip, DialogContent, useTheme, Box } from "@mui/material";
import { toast } from "react-toastify";
import Header from "component/Header";
import CreateEditMachineModel from "pages/Assets/MachineModels/CreateEditMachineModel";
import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedPDF from "component/AuthorizedPDF";
import ArtifactsDialogBox from "component/ArtifactsDialogBox";
import videoImage from "images/Video.png";

const MachineModel = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { state } = useLocation();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    model: "",
    info: { artifacts: [] },
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const { appConstants } = useSelector((state) => state.service);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogView, setShowDialogView] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (id && appConstants) {
      const modelInfo = appConstants?.machineModels?.find((modelItem) => modelItem.id === parseInt(id));
      const info = modelInfo?.info ? JSON.parse(modelInfo?.info) : {};
      setInitialFormData({ ...modelInfo, info });
    }
  }, [appConstants]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.model]);

  const handleFileSelect = (event) => {
    setSelectedFiles([...event.target.files]);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedFiles([]);
    // fileInputRef.current.value = null;
  };
  const goToPrevSlide = () => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
  const goToNextSlide = () => setCurrentIndex(currentIndex === selectedFiles.length - 1 ? 0 : currentIndex + 1);

  const submitData = () => {
    const modelFormData = new FormData();
    const artifacts = [];
    const updateObj = { manufacturer: initialFormData.manufacturer, modelName: initialFormData.model, modelId: id };
    if (selectedFiles.length) {
      selectedFiles.forEach((item, index) => {
        modelFormData.append("image" + index, item);
        artifacts.push({
          name: item.name,
          type: item.type.includes("image/")
            ? appConstants.allTypes.TYPE_OF_CONTENT.IMAGE
            : item.type.includes("application/pdf")
            ? appConstants.allTypes.TYPE_OF_CONTENT.PDF
            : appConstants.allTypes.TYPE_OF_CONTENT.VIDEO,
          image: "image" + index,
        });
      });
      updateObj.artifacts = artifacts;
    }
    modelFormData.append("modelDetails", JSON.stringify(updateObj));
    setShowDialog(false);
    setSelectedFiles([]);
    dispatch(updateMachineModel(modelFormData)).then((res) => {
      if (res && res.error) toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        dispatch(readAsyncStorageValues()).then((resp) => {
          toast.success("Artifact(s) added successfully!");
          // props.setEnableEditing(false);
        });
      } else toast.error("Something went wrong!");
    });
  };
  const handleViewFile = (articfactIndex) => {
    setCurrentIndex(articfactIndex);
    setShowDialogView(true);
  };
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Machine Model", id ? initialFormData.model : ""]} />
      </Stack>
      <div>
        <Dialog open={showDialog} onClose={handleCloseDialog}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <DialogTitle>Selected File</DialogTitle>
            <DialogActions>
              <Button onClick={goToPrevSlide}>{"< <"} Prev</Button>
              <Button onClick={goToNextSlide}>Next {"> >"}</Button>
            </DialogActions>
          </Stack>

          <DialogContent>
            {/* File preview */}
            {selectedFiles.length ? (
              selectedFiles[currentIndex].type.includes("image") ? (
                <img
                  src={URL.createObjectURL(selectedFiles[currentIndex])}
                  alt="Selected File"
                  style={{ maxWidth: "100%", maxHeight: "500px", minHeight: "200px" }}
                />
              ) : selectedFiles[currentIndex].type.includes("pdf") ? (
                <embed src={URL.createObjectURL(selectedFiles[currentIndex])} width="800" height="700" />
              ) : (
                <span>Selected File is Incorrect</span>
              )
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            {selectedFiles.length ? (
              <Button variant="contained" onClick={submitData}>
                Upload
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
      <div>
        {showDialogView && (
          <ArtifactsDialogBox
            showDialogView={showDialogView}
            data={initialFormData?.info?.artifacts.length ? initialFormData?.info?.artifacts : []}
            setShowDialogView={setShowDialogView}
            selectedInitialIndex={currentIndex}
          />
        )}
      </div>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <CreateEditMachineModel initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
              <Stack rowGap={1}>
                <Typography>Model : {initialFormData.model}</Typography>
                <Typography>Manufacturer : {initialFormData.manufacturer}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Tooltip title="Edit model">
                  <Button onClick={() => setEnableEditing(true)} variant="contained">
                    Edit
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        )}
      </Box>
      {id && (
        <Box sx={{ mx: 2 }}>
          <Paper sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h5" fontWeight={600} mb={1}>
                Artifacts
              </Typography>
              <input
                type="file"
                accept="application/pdf,video/*,image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect}
                id="fileInput"
                multiple
              />
              <Tooltip title="Add file">
                <Button
                  sx={{ height: "self" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCurrentIndex(0);
                    document.getElementById("fileInput").click();
                  }}
                >
                  Add
                </Button>
              </Tooltip>
            </Stack>

            {initialFormData?.info && initialFormData?.info?.artifacts ? (
              <Stack direction="row" spacing={2} sx={{ overflow: "auto", pb: 1 }}>
                {initialFormData.info.artifacts.map((artifact, index) => (
                  <Paper sx={{ borderRadius: 2, p: 2 }}>
                    <Stack rowGap={1} onClick={() => handleViewFile(index)}>
                      {artifact.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE ? (
                        <AuthorizedImage path={artifact.url} width={150} height={150} />
                      ) : artifact.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF ? (
                        <AuthorizedPDF path={artifact.url} scale={0.135} width={150} />
                      ) : (
                        <div style={{ width: 100, height: 100 }}>
                          <img src={videoImage} width={100} height={100} />
                        </div>
                      )}
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : null}
          </Paper>
        </Box>
      )}
    </>
  );
};

export default MachineModel;
