import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { getMachines, getMachineDepartments, readSpareParts } from "features/service/service.slice";
import { Card, Stack, Paper, Typography, List, Button, Dialog, Tooltip, DialogContent, DialogActions, Box } from "@mui/material";
import { QrCode as QRCodeIcon, Download as DownloadIcon, FileUpload as FileUploadIcon } from "@mui/icons-material";
import Header from "component/Header";
import AddEditSpare from "pages/Assets/SpareParts/AddEditSpare";
import AuthorizedImage from "component/AuthorizedImage";

const SparePart = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    partCode: "",
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const { isLoading, machineDepartments, spareParts } = useSelector((state) => state.service);
  const { appConstants } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getMachineDepartments());
    dispatch(readSpareParts());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.partCode]);
  useEffect(() => {
    if (id && spareParts.length && machineDepartments.length) {
      const sparePartInfo = spareParts.find((spare) => spare.id == id);
      setInitialFormData({ ...sparePartInfo, info: sparePartInfo.info || "There is no information to display" });
    }
  }, [spareParts, machineDepartments]);
  const handleQRCodeClick = async () => {
    const data = `
      partCode:${initialFormData.partCode}
      maker:${initialFormData.maker}
      description:${initialFormData.description}`;

    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

    try {
      const response = await fetch(qrCodeApiUrl);
      if (!response.ok) {
        console.error("Failed to generate QR code.");
        return;
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      const custName = appConstants?.customers
        ?.filter((customer) => customer.id == 5284)[0]
        ?.customerName.split(" ")
        .join("_");
      downloadLink.download = `${custName}_qr_code_sparePart_${initialFormData.partCode}.png`;

      downloadLink.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Spare part", id ? initialFormData.partCode : ""]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditSpare initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack direction={"row"}>
              <Stack mr={3}>
                <Box sx={{}}>
                  {initialFormData.image ? <AuthorizedImage path={initialFormData.image} height={200} width={200} /> : null}
                </Box>
              </Stack>
              <Stack sx={{ width: "90%" }}>
                <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
                  <Stack rowGap={1} ml={3}>
                    <Typography>Maker : {initialFormData.maker}</Typography>
                    <Typography>Part code : {initialFormData.partCode}</Typography>
                    <Typography>Reorder level : {initialFormData.reorderLevel || "-"}</Typography>
                    <Typography>Reorder quantity : {initialFormData.reorderQuantity}</Typography>
                    <Typography>Location : {initialFormData.location || "-"}</Typography>
                  </Stack>
                  <Stack rowGap={1}>
                    <Typography>Unit : {initialFormData.unit}</Typography>
                    <Typography>Current stock : {initialFormData.currentStock}</Typography>
                    <Typography>Issued : {initialFormData.issued ? "Yes" : "No"}</Typography>
                    <Typography>Returned : {initialFormData.returned ? "Yes" : "No"}</Typography>
                  </Stack>
                  <Stack rowGap={1}>
                    <Tooltip title="Edit asset">
                      <Button variant="contained" onClick={() => setEnableEditing(true)}>
                        Edit
                      </Button>
                    </Tooltip>
                    <Tooltip title="Download QR">
                      <Button variant="contained" endIcon={<DownloadIcon />} onClick={handleQRCodeClick}>
                        <QRCodeIcon />
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack m={3} rowGap={1}>
                  <Typography flexWrap={"wrap"}>Description : {initialFormData.description}</Typography>
                  <Typography>Info : {initialFormData.info}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        )}
      </Box>
    </>
  );
};

export default SparePart;
