import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography, useTheme } from "@mui/material";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Cell, ResponsiveContainer } from "recharts";
import { useNavigate } from "react-router-dom";

import { getOverdueServiceTickets } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
// import { useTheme } from "@emotion/react";

const OverdueTicketsByTicketCategory = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [overdueServiceTicketsByTicketCategory, setOverdueServiceTicketsByTicketCategory] = useState([]);
  const { overdueServiceTickets } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getOverdueServiceTickets());
  }, [dispatch]);

  useEffect(() => {
    if (overdueServiceTickets.data) {
      const dataGroupBy = overdueServiceTickets.data.reduce((acc, curr) => {
        acc[curr.ticketCategory] = acc[curr.ticketCategory] || [];
        acc[curr.ticketCategory].push(curr);
        return acc;
      }, Object.create(null));

      const data = [];
      for (const [key, value] of Object.entries(dataGroupBy)) {
        data.push({
          ticketCategory: key,
          count: value.length,
          tickets: value,
          color: "#00000",
        });
      }

      setOverdueServiceTicketsByTicketCategory(data);
    }
  }, [overdueServiceTickets.data]);

  const goToServiceTickets = (e) => {
    let queryParam = ``;
    e.tickets.forEach((ticket, index) => {
      queryParam = index === 0 ? queryParam + "?" : queryParam + "&";
      queryParam = queryParam + `ticketId[]=${ticket.id}`;
    });
    navigate(`/${Links.ALL_SERVICE_TICKETS}${queryParam}`);
  };

  return (
    <Paper elevation={8} sx={{ padding: 1 }}>
      <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Overdue Tickets by Ticket Category
      </Typography>
      <ResponsiveContainer width="99%" aspect={1}>
        <ComposedChart
          data={overdueServiceTicketsByTicketCategory ? overdueServiceTicketsByTicketCategory : []}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          cursor="pointer"
        >
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="ticketCategory" stroke={mode === "dark" ? "#fff" : "#000"} />
          <YAxis allowDecimals={false} stroke={mode === "dark" ? "#fff" : "#000"}>
            <Label angle={270} position="insideLeft" offset={10} value="Count" stroke={mode === "dark" ? "#fff" : "#7F7F7F"} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="count"
            name="Tickets"
            barSize={50}
            label={{ position: "top" }}
            isAnimationActive={false}
            onClick={goToServiceTickets}
            fill="#781e7d"
          >
            {overdueServiceTickets?.data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#781e7d" />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default OverdueTicketsByTicketCategory;
