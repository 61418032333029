import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Service from "./service";

const initialState = {
  isLoading: false,
  isError: false,
  message: "",
  allUsers: [],
  allVendorsList: {
    isLoading: true,
    data: [],
  },
};

export const getAllVendors = createAsyncThunk("vendor/getAllVendors", async (payload, thunkAPI) => {
  try {
    return await Service.getAllVendors(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createVendor = createAsyncThunk("vendor/createVendor", async (payload, thunkAPI) => {
  try {
    return await Service.createVendor(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateVendor = createAsyncThunk("vendor/updateVendor", async (payload, thunkAPI) => {
  try {
    return await Service.updateVendor(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateVendorContact = createAsyncThunk("vendor/updateVendorContact", async (payload, thunkAPI) => {
  try {
    return await Service.updateVendorContact(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const addUser = createAsyncThunk("user/addUser", async (payload, thunkAPI) => {
  try {
    return await Service.addUser(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateUser = createAsyncThunk("user/updateUser", async (payload, thunkAPI) => {
  try {
    return await Service.updateUser(payload);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getAllUsers = createAsyncThunk("user/getAllUsers", async (user, thunkAPI) => {
  try {
    return await Service.getAllUsers();
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const appUsersSlice = createSlice({
  name: "appUsers",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.machines = action.payload.data;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoading = false;
        // state.machines = [];
        state.message = "";
      })
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allUsers = action.payload.data;
        state.message = "";
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.allUsers = [];
        state.message = "";
      })
      .addCase(getAllVendors.pending, (state) => {
        state.allVendorsList.isLoading = true;
        state.allVendorsList.data = [];
      })
      .addCase(getAllVendors.fulfilled, (state, action) => {
        state.allVendorsList.isLoading = false;
        state.allVendorsList.data = action.payload.data;
      })
      .addCase(getAllVendors.rejected, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(createVendor.pending, (state) => {
        state.allVendorsList.isLoading = true;
      })
      .addCase(createVendor.fulfilled, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(createVendor.rejected, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(updateVendor.pending, (state) => {
        state.allVendorsList.isLoading = true;
      })
      .addCase(updateVendor.fulfilled, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(updateVendor.rejected, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(updateVendorContact.pending, (state) => {
        state.allVendorsList.isLoading = true;
      })
      .addCase(updateVendorContact.fulfilled, (state, action) => {
        state.allVendorsList.isLoading = false;
      })
      .addCase(updateVendorContact.rejected, (state, action) => {
        state.allVendorsList.isLoading = false;
      });
  },
});

export const { reset } = appUsersSlice.actions;

export default appUsersSlice.reducer;
