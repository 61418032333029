import React, { useEffect, useState } from "react";
import Form from "component/form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readSpareParts, getMachineDepartments, updateSpareParts, addSpareParts } from "features/service/service.slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Links } from "utils/pageLinkNames";
import { CircularProgress, Stack, TextField, Tooltip, Button, Card } from "@mui/material";
import AuthorizedImage from "component/AuthorizedImage";
import { QrCode as QRCodeIcon, FileUpload as FileUploadIcon } from "@mui/icons-material";

const AddEditSpare = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [imageToUpload, setImageToUpload] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    maker: "",
    partCode: "",
    unit: "",
    location: "",
    currentStock: "",
    reorderLevel: "",
    reorderQuantity: "",
    issued: "",
    returned: "",
    description: "",
    info: "",
    image: "",
  });
  const { isLoading, machineDepartments, spareParts } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props && props.initialFormData) setFormData(props.initialFormData);
  }, [spareParts, machineDepartments]);

  const validations = {
    maker: true,
    partCode: true,
  };

  const handleSubmit = () => {
    if (!formData.maker || !formData.partCode) {
      setErrors({
        maker: formData.serialNumber ? "" : "Please enter maker!",
        partCode: formData.model ? "" : "Please enter part code!",
      });
      return toast.warn("Please enter required fields!");
    }
    const spareExists = spareParts.some((item) => item.partCode === formData.partCode);
    const formDataToBe = new FormData();
    if (spareExists && !id) {
      toast.error("This data already exists!");
    } else {
      formData.currentStock = parseInt(formData.currentStock);
      formData.issued = parseInt(formData.issued);
      formData.returned = parseInt(formData.returned);
      formData.reorderLevel = parseInt(formData.reorderLevel);
      formData.reorderQuantity = parseInt(formData.reorderQuantity);
      if (id) {
        formData.modelPartId = parseInt(id);
        if (imageToUpload) {
          formDataToBe.append("image", imageToUpload);
          delete formData.image;
        }
        formDataToBe.append("spareDetails", JSON.stringify(formData));
        submitForm(updateSpareParts, formDataToBe);
      } else {
        if (imageToUpload) {
          formDataToBe.append("image", imageToUpload);
          delete formData.image;
        }
        formDataToBe.append("spareDetails", JSON.stringify(formData));
        submitForm(addSpareParts, formDataToBe);
      }
    }
  };

  const submitForm = (action, formData) => {
    dispatch(action(formData)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        if (!formData.modelPartId) navigate(`/${Links.EDIT_SPARE_PART}/` + res.payload.data.id, { replace: true });
        dispatch(readSpareParts()).then((res) => {
          toast.success("Spare part " + (id ? "updated" : "added") + " successfully!");
          props.setEnableEditing(false);
        });
      } else return toast.error("Something went wrong!");
    });
  };

  const handleFileUpload = (selectedFile) => {
    setImageToUpload(selectedFile.target.files[0]);
    // toast.success("Image uploaded successfully!");
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <div>
      <Stack gap={2}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <Stack alignItems={"center"} sx={{ flex: 0.2 }}>
            <Card sx={{ borderRadius: 5 }}>
              <Stack alignItems={"center"} p={3} gap={2}>
                <Button startIcon={<FileUploadIcon />} component="label" sx={mode === "dark" ? { color: "white" } : {}}>
                  Upload
                  <input
                    style={{ display: "none" }}
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    id="fileInput"
                    onChange={handleFileUpload}
                  />
                </Button>
                {imageToUpload ? (
                  <img
                    src={URL.createObjectURL(imageToUpload)}
                    alt="Selected File"
                    style={{ maxWidth: "100%", maxHeight: "500px" }}
                    // onClick={() => handleViewFile(imageToUpload)}
                  />
                ) : formData.image ? (
                  <AuthorizedImage path={formData.image} height={250} width={300} />
                ) : null}
              </Stack>
            </Card>
          </Stack>
          <Stack flexWrap={"wrap"} sx={{ flex: 0.7 }}>
            <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
              <TextField
                label="Part code"
                value={formData.partCode}
                onChange={(event) => setFormData({ ...formData, partCode: event.target.value })}
                error={errors && !formData.partCode}
                helperText={errors.partCode}
              />
              <TextField
                label="Maker"
                value={formData.maker}
                onChange={(event) => setFormData({ ...formData, maker: event.target.value })}
                error={errors && !formData.maker}
                helperText={errors.maker}
              />
              <TextField
                label="Location"
                value={formData.location}
                onChange={(event) => setFormData({ ...formData, location: event.target.value })}
              />
              <TextField label="Unit" value={formData.unit} onChange={(event) => setFormData({ ...formData, unit: event.target.value })} />
              <TextField
                label="Reorder level"
                value={formData.reorderLevel}
                onChange={(event) => setFormData({ ...formData, reorderLevel: event.target.value })}
              />
              <TextField
                label="Reorder quantity"
                value={formData.reorderQuantity}
                onChange={(event) => setFormData({ ...formData, reorderQuantity: event.target.value })}
              />
              <TextField
                label="Current stock"
                value={formData.currentStock}
                onChange={(event) => setFormData({ ...formData, currentStock: event.target.value })}
              />
              <TextField
                label="Issued"
                value={formData.issued}
                onChange={(event) => setFormData({ ...formData, issued: event.target.value })}
              />
              <TextField
                label="Returned"
                value={formData.returned}
                onChange={(event) => setFormData({ ...formData, returned: event.target.value })}
              />
              <TextField
                label="description"
                multiline
                value={formData.description}
                onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                sx={{ minWidth: 300 }}
              />
              <TextField
                label="Info"
                multiline
                value={formData.info}
                onChange={(event) => setFormData({ ...formData, info: event.target.value })}
                sx={{ minWidth: 300 }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flex: 0.1 }} alignItems={"center"}>
            <Stack>
              <Tooltip title="Save">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <ToastContainer />
    </div>
  );
};

export default AddEditSpare;
