import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUser, updateUser, getAllUsers } from "features/appUsers/appUsers.slice";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Links } from "utils/pageLinkNames";
import { isEmail, isPhoneNumber } from "utils/utilFunctions";
import {
  Typography,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Autocomplete,
  Checkbox,
  InputAdornment,
  IconButton,
} from "@mui/material";

const AddEditUser = (props) => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    department: "",
    userRole: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({});

  const { appConstants } = useSelector((state) => state.service);
  const { allUsers, isLoading } = useSelector((state) => state.appUsers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (props && props.initialFormData) setFormData(props.initialFormData);
  }, [appConstants, allUsers]);

  const handleSubmit = () => {
    const errorValidations = {
      name: formData.name ? "" : "Please enter name!",
      userName: formData.userName ? "" : "Please enter user name!",
      userRole: formData.userRole ? "" : "Please enter user role!",
      department: formData.department ? "" : "Please enter department!",
      mobileNumber: "",
      email: "",
    };
    if (!id && !formData.password) errorValidations.password = formData.password ? "" : "Please enter password!";
    if (!id && formData.password !== formData.confirmPassword) errorValidations.confirmPassword = "Password doesnt match!";
    if (id && formData.password)
      errorValidations.confirmPassword = formData.password !== formData.confirmPassword ? "Password doesnt match!" : "";
    if (Object.values(errorValidations).filter((item) => item).length) {
      setErrors(errorValidations);
      return toast.warn("Please enter required fields!");
    }
    if (formData.email && !isEmail(formData.email)) {
      errorValidations.email = "Please enter a valid email!";
      setErrors(errorValidations);
      return toast.warn("Please enter a valid email!");
    }
    if (formData.mobileNumber?.trim().length > 0 && !isPhoneNumber(formData.mobileNumber)) {
      errorValidations.mobileNumber = "Please enter a valid mobile number!";
      setErrors(errorValidations);
      return toast.warn("Please enter a valid  mobile number!");
    }
    //
    const userExists = allUsers.some((item) => item.userName.toLowerCase() === formData.userName.toLowerCase());
    if (
      userExists &&
      (!id || allUsers.some((item) => item.userId == id && item.userName.toLowerCase() !== formData.userName.toLowerCase()))
    ) {
      return toast.error("Username already exists!");
    } else {
      // formData.department = appConstants.departments.find((item) => item.name == formData.department).id || 50;
      formData.department = formData.department.id || 50;
      delete formData.departmentId;
      delete formData.confirmPassword;
      if (formData.email === "" || formData.email == null) delete formData.email;
      if (id) {
        if (!formData.password) delete formData.password;
        formData.userId = parseInt(id);
        submitForm(updateUser, formData);
      } else submitForm(addUser, formData);
    }
  };

  const submitForm = (action, formData) => {
    dispatch(action(formData)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        navigate(`/${Links.EDIT_USER}/` + res.payload.data.id, { replace: true });
        dispatch(getAllUsers()).then((res) => {
          toast.success("User " + (formData.userId ? "updated" : "added") + " successfully!");
          props.setEnableEditing(false);
        });
      } else return toast.error("Something went wrong!");
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <div>
      <Stack direction={"row"} gap={2}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"}>
          <TextField
            label="Name"
            value={formData.name}
            autoComplete="new-name"            
            onChange={(event) => setFormData({ ...formData, name: event.target.value })}
            error={errors && errors.name}
            helperText={errors.name}
            sx={{ minWidth: 350, maxWidth: 600 }}
          />
          <TextField
            label="User name"
            autoComplete="new-username"            
            value={formData.userName}
            onChange={(event) => setFormData({ ...formData, userName: event.target.value })}
            error={errors && errors.userName}
            helperText={errors.userName}
            sx={{ minWidth: 350, maxWidth: 600 }}
          />
          <TextField
            label="Email"
            value={formData.email}
            autoComplete="new-email"            
            onChange={(event) => setFormData({ ...formData, email: event.target.value })}
            error={errors && errors.email && !isEmail(formData.email)}
            helperText={errors.email}
            sx={{ minWidth: 350, maxWidth: 600 }}
          />
          <TextField
            label="Mobile Number"
            autoComplete="new-mobileNum"            
            value={formData.mobileNumber}
            onChange={(event) => setFormData({ ...formData, mobileNumber: event.target.value })}
            error={errors && errors.mobileNumber && !isPhoneNumber(formData.mobileNumber)}
            helperText={errors.mobileNumber}
            sx={{ minWidth: 350, maxWidth: 600 }}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            autoComplete="new-role"            
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                userRole: newValue,
              })
            }
            value={formData.userRole || null}
            options={
              appConstants && Object.keys(appConstants).length
                ? Object.values(appConstants.allTypes.CALMAN_USER_ROLES).sort((a, b) => a.localeCompare(b))
                : []
            }
            sx={{ minWidth: 350, maxWidth: 600 }}
            renderInput={(params) => <TextField {...params} label="Role" error={errors && errors.userRole} helperText={errors.userRole} />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                department: newValue,
              })
            }
            value={formData.department || null}
            autoComplete="new-department"            
            options={
              appConstants && Object.keys(appConstants).length
                ? appConstants.departments
                    .map((item) => ({ ...item, value: item.name, label: item.name }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                : []
            }
            sx={{ minWidth: 350, maxWidth: 600 }}
            renderInput={(params) => (
              <TextField {...params} label="Department" error={errors && errors.department} helperText={errors.department} />
            )}
          />
          <TextField
            label="Password"
            type={showPassword.password ? "text" : "password"}
            value={formData.password || null}
            onChange={(event) => setFormData({ ...formData, password: event.target.value })}
            error={errors && !id && errors.password}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                    edge="end"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 350, maxWidth: 600 }}
            autoComplete="new-password"
          />
          <TextField
            label="Confirm password"
            type={showPassword.confirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
                    edge="end"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={(errors && !id && errors.confirmPassword) || errors.password !== errors.confirmPassword}
            helperText={errors.confirmPassword}
            sx={{ minWidth: 350, maxWidth: 600 }}
            autoComplete="new-password"
          />
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={formData.enabled ? true : false}
              onChange={(event) => {
                console.log(event.target.checked);
                setFormData({
                  ...formData,
                  enabled: event.target.checked,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>
              {formData.enabled ? "Enabled(Uncheck the box to disable user)" : "Disabled(Check the box to enable user)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Tooltip title="Save">
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <ToastContainer />
    </div>
  );
};

export default AddEditUser;
