import React from "react";
import { Box, Stack } from "@mui/material";
import ChartTicketsByStatus from "component/ChartTicketsByStatus";
import OverdueTicketsByTicketCategory from "component/OverdueTicketsByTicketCategory";

const ServiceDashboard = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack mt={2} spacing={1}>
        <Stack direction="row" ml={4} mr={2} gap={2} flex={1}>
          <Box flex={1}>
            <ChartTicketsByStatus />
          </Box>
          <Box flex={1}>
            <OverdueTicketsByTicketCategory />
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ServiceDashboard;
