import moment from "moment";

const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType });

  const a = document.createElement("a");
  a.download = fileName;
  a.href = window.URL.createObjectURL(blob);
  const clickEvt = new MouseEvent("click", {
    view: window,
    bubbles: true,
    cancelable: true,
  });
  a.dispatchEvent(clickEvt);
  a.remove();
};

export const exportToCsv = (headers = [], keys = [], data = [], fileName = "Airman") => {
  // Headers for each column

  // Convert sparePart data to a csv
  const dataCsv = data.reduce((acc, item) => {
    // const { id, maker, partCode, unit, location, description, currentStock, issued, returned } = sparePart;
    const dataObj = {};
    keys.forEach((key) => (dataObj[key] = item[key]));
    acc.push(Object.values(dataObj).join(","));
    return acc;
  }, []);
  console.log(fileName + "_" + moment(new Date()).format("DD_MMM_YYYY_HH_mm") + ".csv");
  downloadFile({
    data: [...headers, ...dataCsv].join("\n"),
    fileName: fileName + "_" + moment(new Date()).format("DD_MMM_YYYY_HH_mm") + ".csv",
    fileType: "text/csv",
  });
};

export const isEmail = (value) => {
  const emailRegex = /^\S+@\S+\.\S+$/;
  return emailRegex.test(value);
};

export const isPhoneNumber = (value) => {
  const phoneNumberRegex = /^[+]?[(]?\d{1,4}[)]?[-\s./]?\d{1,3}[-\s./]?\d{1,3}[-\s./]?\d{1,4}$/;
  return phoneNumberRegex.test(value);
};

export const isYear = (value) => {
  const regex = /^[1-9]\d{3}$/; // regular expression for four-digit number starting with a non-zero digit
  return regex.test(value);
};
