import React, { useEffect } from "react";
import { Container, Typography, Link, Box } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "component/loginForm";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Navigate } from "react-router-dom";
import { Links } from "utils/pageLinkNames";
import { reset } from "features/global/global.slice";

//////////////////////////////////
const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { appConstants, isLoading } = useSelector((state) => state.service);

  useEffect(() => {
    if (!isAuthenticated) dispatch(reset());
  });
  if (isAuthenticated)
    return user.roles[0] == appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR ? (
      <Navigate to={`/${Links.PLANT_OVERVIEW}`} state={{ from: location }} replace />
    ) : user.roles[0] == appConstants?.allTypes?.USER_ROLES?.ROLE_FINANCE ? (
      <Navigate to={`/${Links.ASSETS}`} state={{ from: location }} replace />
    ) : (
      <Navigate to={`/${Links.USER}`} state={{ from: location }} replace />
    );
  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Box>
              <Link to="/">
                <Box sx={{ marginY: 2, width: 300, resizeMode: "contain" }} component="img" src="app-logo.png" alt="logo" />
              </Link>
            </Box>
            <Typography sx={{ color: "text.secondary", mb: 5 }}>Login to your account</Typography>
          </HeadingStyle>

          {/* <Box component={motion.div} {...fadeInUp}>
            <SocialAuth />
          </Box> */}

          {/* <Divider sx={{ my: 3 }} component={motion.div} {...fadeInUp}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              OR
            </Typography>
          </Divider> */}
          <LoginForm />

          {/* <Typography
            component={motion.p}
            {...fadeInUp}
            variant="body2"
            align="center"
            sx={{ mt: 3 }}
          >
            Don’t have an account?{" "}
            <Link variant="subtitle2" component={RouterLink} to="/signup">
              Sign up
            </Link>
          </Typography> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
