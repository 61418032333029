import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { getMachines, getMachineDepartments, readServiceTicketsByMachineId } from "features/service/service.slice";
import { Card, Stack, Paper, Typography, List, Button, Dialog, Tooltip, DialogContent, useTheme, Box, IconButton } from "@mui/material";
import { QrCode as QRCodeIcon, Download as DownloadIcon } from "@mui/icons-material";
import moment from "moment";
import Header from "component/Header";
import { serviceTicketForMachineTableAttributes } from "data/dataTovisualize";
import DataGridTable from "component/DataGridTable";
import AddEditMachine from "pages/Assets/Machines/AddEditMachine";
import { Link } from "react-router-dom";
import { Links } from "utils/pageLinkNames.js";
import { ZoomIn as ViewIcon } from "@mui/icons-material";

const Machine = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { state } = useLocation();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    serialNumber: "",
  });
  const [serviceTickets, setServiceTickets] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [enableEditing, setEnableEditing] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const { machinesData, appConstants, machineDepartments, serviceTicketMachine } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    if (serviceTicketMachine) {
      const modified = serviceTicketMachine.map((ticket) => {
        return {
          ...ticket,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
        };
      });
      setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
    }
  }, [serviceTicketMachine]);
  useEffect(() => {
    dispatch(getMachineDepartments());
    dispatch(getMachines());
    dispatch(readServiceTicketsByMachineId(id));
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.serialNumber]);

  useEffect(() => {
    if (id && machinesData.length && machineDepartments.length) {
      const info = machinesData.find((machine) => machine.id == id);
      setInitialFormData({ ...info, location: info.department });
    }
  }, [appConstants, machinesData]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const gridTicketEdit = (args) => {
    return (
      <div>
        {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
          <Link to={`/${Links.SERVICE_TICKET}/` + args.row.ticketId}>
            <IconButton sx={{ mr: 1 }}>
              <Tooltip title="View">
                <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
              </Tooltip>
            </IconButton>
          </Link>
        ) : null}
      </div>
    );
  };

  const serviceTicketForMachineTableAttributes = [
    {
      field: "dateCreated",
      headerName: "Created",
      minWidth: 60,
      flex: 0.3,
      sortComparator: dateComparator,
    },
    {
      field: "ticketId",
      headerName: "Ticket Id",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 60,
      flex: 0.3,
      sortable: false,
      filterable: false,
      renderCell: gridTicketEdit,
    },
  ];

  const handleQRCodeClick = async () => {
    const data = `
        Code: ${initialFormData.maintenanceCode}
        Mfg: ${initialFormData.manufacturer}
        Model: ${initialFormData.model}
        SN: ${initialFormData.serialNumber}
        Location: ${initialFormData.department}
        `;

    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

    try {
      const response = await fetch(qrCodeApiUrl);
      if (!response.ok) {
        console.error("Failed to generate QR code.");
        return;
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      const custName = appConstants?.customers
        ?.filter((customer) => customer.id == 5284)[0]
        ?.customerName.split(" ")
        .join("_");
      downloadLink.download = `${custName}_qr_code_machine_${initialFormData.maintenanceCode}.png`;

      downloadLink.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Assets", id ? initialFormData.serialNumber : ""]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditMachine initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
              <Stack rowGap={1}>
                <Typography>Serial number : {initialFormData.serialNumber}</Typography>
                <Typography>Model : {initialFormData.model}</Typography>
                <Typography>Manufacturer : {initialFormData.manufacturer}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Typography>Maintenance code : {initialFormData.maintenanceCode}</Typography>
                <Typography>Asset number : {initialFormData.assetNumber || "-"}</Typography>
                <Typography>Manufacturing year : {initialFormData.manufacturingYear}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Typography>Critical machine : {initialFormData.criticalMachine ? "Yes" : "No"}</Typography>
                <Typography>Machine type : {initialFormData.machineType || "-"}</Typography>
                <Typography>Location : {initialFormData.location}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Tooltip title="Edit asset">
                  <Button onClick={() => setEnableEditing(true)} variant="contained">
                    Edit
                  </Button>
                </Tooltip>
                <Tooltip title="Download QR">
                  <Button endIcon={<DownloadIcon />} variant="contained" onClick={handleQRCodeClick}>
                    <QRCodeIcon />
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        )}
        {id ? (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5" fontWeight={600} mb={1}>
              Tickets
            </Typography>
            <DataGridTable
              rowId={"ticketId"}
              data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
              columnAttributes={serviceTicketForMachineTableAttributes}
            />
          </Paper>
        ) : null}
      </Box>
    </>
  );
};

export default Machine;
