import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  PictureInPicture,
  List as ListIcon,
  ListAlt,
  Task,
  Schedule,
  WebAsset,
  VerifiedUser,
  People,
  Build,
  Shop,
  AddAlert as ActionRequiredIcon,
  LocalOffer as AssignTechnicianIcon,
  CrisisAlert as PendingIcon,
  AutoAwesomeMosaic as MachinModelIcon,
} from "@mui/icons-material";

import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Links } from "utils/pageLinkNames";

const navItems = [
  {
    text: "Dashboards",
  },
  {
    text: "Plant Overview",
    navigateTo: Links.PLANT_OVERVIEW,
    icon: <AssignmentRoundedIcon />,
  },
  {
    text: "Breakdown Hours",
    navigateTo: Links.BREAKDOWN,
    icon: <TimelineIcon />,
  },
  {
    text: "Tickets Overview",
    navigateTo: Links.SERVICE_DASHBOARD,
    icon: <HomeOutlined />,
  },
  {
    text: "Service Tickets",
  },
  {
    text: "Active",
    navigateTo: Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS,
    icon: <AssignTechnicianIcon />,
  },
  {
    text: "Action Required",
    navigateTo: Links.ACTION_REQUIRED_SERVICE_TICKETS,
    icon: <ActionRequiredIcon />,
  },
  {
    text: "Pending",
    navigateTo: Links.PENDING_SERVICE_TICKETS,
    icon: <PendingIcon />,
  },
  {
    text: "All",
    navigateTo: Links.ALL_SERVICE_TICKETS,
    icon: <ListIcon />,
  },
  {
    text: "Data",
  },
  {
    text: "Reports",
    navigateTo: Links.REPORTS,
    icon: <Task />,
  },
  {
    text: "Scheduling",
    navigateTo: Links.SCHEDULING,
    icon: <Schedule />,
  },
  {
    text: "Assets",
    navigateTo: Links.ASSETS,
    icon: <WebAsset />,
  },
  {
    text: "Users",
    navigateTo: Links.USER,
    icon: <People />,
  },
  {
    text: "Vendors",
    navigateTo: Links.VENDORS,
    icon: <Shop />,
  },
  {
    text: "Spare Parts",
    navigateTo: Links.INVENTORY,
    icon: <ListAlt />,
  },
  {
    text: "Locations",
    navigateTo: Links.LOCATIONS,
    icon: <LocationOnIcon />,
  },
  {
    text: "Templates",
    navigateTo: Links.TEMPLATES,
    icon: <PictureInPicture />,
  },
  {
    text: "Machine Models",
    navigateTo: Links.MACHINE_MODELS,
    icon: <MachinModelIcon />,
  },
  // {
  //   text: "Inspection",
  //   navigateTo: Links.INSPECTION,
  //   icon: <VerifiedUser />,
  // },
];
const navItemsFinance = [
  {
    text: "Data",
  },
  {
    text: "Assets ",
    navigateTo: Links.ASSETS,
    icon: <WebAsset />,
  },
];

const navItemsIt = [
  {
    text: "Data",
  },
  {
    text: "Users",
    navigateTo: Links.USER,
    icon: <People />,
  },
];

const Container = styled("Box")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { appConstants } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.auth);
  const { mode } = useSelector((state) => state.globalState);

  let navigate = useNavigate();

  const theme = useTheme();
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const onClickItem = (navigateTo) => {
    navigate(`/${navigateTo}`);
    setActive(navigateTo);
  };
  return (
    <div>
      <Box component="nav" sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          open
          variant="permanent"
          anchor="left"
          transitionDuration={500}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: props.drawerWidth,
              backgroundColor: mode === "dark" ? theme.palette.grey[900] : theme.palette.primary[200],
            },
          }}
        >
          <Box width="100%" height="100%" sx={{ backgroundColor: mode === "dark" ? theme.palette.grey[900] : theme.palette.primary[200] }}>
            <Box m="1.5rem 1rem 1rem 2rem">
              <Container>
                <Box display="flex" alignItems="center" gap="0.1rem">
                  <Box
                    sx={{ width: 200, resizeMode: "contain", marginLeft: -2, marginBottom: -5, marginTop: -3 }}
                    component="img"
                    src="app-logo.png"
                    alt="logo"
                  />

                  {/* <Typography variant="h4" fontWeight="bold" sx={{ color: "#fff" }}>
                    CALMAN
                  </Typography> */}
                </Box>
                {/* <IconButton>
                <ChevronLeft sx={{ color: "white" }} />
              </IconButton> */}
              </Container>
            </Box>
            {user.roles[0] == appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
              <List>
                {navItems.map(({ text, icon, navigateTo }) => {
                  if (!icon) {
                    return (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{
                          my: -0.6,
                          pr: "2rem",
                        }}
                      >
                        <Typography key={text} sx={{ m: "1rem 0 1rem 1rem", color: "#fff" }} variant="h5" fontWeight="600">
                          {text}
                        </Typography>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "#fff" : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "1rem",
                            mr: -2,
                            color: "#fff",
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ color: "#fff" }} />
                        {/* {active === navigateTo && (
                      <ChevronRightOutlined sx={{ ml: "auto", color: "white" }} />
                    )} */}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : user.roles[0] == appConstants?.allTypes?.USER_ROLES.ROLE_FINANCE ? (
              <List>
                {navItemsFinance.map(({ text, icon, navigateTo }) => {
                  if (!icon) {
                    return (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{
                          my: -0.6,
                          pr: "2rem",
                          color: "#fff",
                        }}
                      >
                        <Typography key={text} sx={{ m: "1rem 0 1rem 1rem" }} variant="h5" fontWeight="600">
                          {text}
                        </Typography>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "white" : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "1rem",
                            mr: -2,
                            color: "#fff",
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ marginRight: "50px", color: "#fff" }} />
                        {/* {active === navigateTo && (
                      <ChevronRightOutlined sx={{ ml: "auto", color: "white" }} />
                    )} */}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            ) : (
              <List>
                {navItemsIt.map(({ text, icon, navigateTo }) => {
                  if (!icon) {
                    return (
                      <ListItem
                        key={text}
                        disablePadding
                        sx={{
                          my: -0.6,
                          pr: "2rem",
                          color: "#fff",
                        }}
                      >
                        <Typography key={text} sx={{ m: "1rem 0 1rem 1rem" }} variant="h5" fontWeight="600">
                          {text}
                        </Typography>
                      </ListItem>
                    );
                  }
                  return (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "white" : theme.palette.secondary[100],
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            ml: "1rem",
                            mr: -2,
                            color: "#fff",
                          }}
                        >
                          {icon}
                        </ListItemIcon>
                        <ListItemText primary={text} sx={{ marginRight: "50px", color: "#fff" }} />
                        {/* {active === navigateTo && (
                      <ChevronRightOutlined sx={{ ml: "auto", color: "white" }} />
                    )} */}
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Box>
        </Drawer>
      </Box>
    </div>
  );
};

export default Sidebar;
