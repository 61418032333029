import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CircularProgress,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  Box,
  useTheme,
  Tooltip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";

import { getMachines, deleteMachine, getMachineDepartments } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";

const Locations = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getMachineDepartments());
  }, [dispatch]);

  const gridLocationAction = (args) => {
    return (
      <div>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_LOCATION}/` + args.row.id)}>
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  };
  const tableGrids = [
    {
      field: "name",
      headerName: "Location",
      minWidth: 200,
      flex: 0.2,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 500,
      flex: 0.4,
    },
    {
      minWidth: 100,
      flex: 0.1,
      sortable: false,
      filterable: false,
      renderCell: gridLocationAction,
    },
  ];

  const [showDialog, setShowDialog] = useState(false);

  const { machinesData, isLoading, machineDepartments } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getMachines());
  }, [dispatch]);

  function handleClick() {
    navigate(`/${Links.ADD_LOCATION}`);
  }

  const selectionsettings = { checkboxMode: "ResetOnRowClick" };
  const toolbarOptions = [{ text: "Add", click: handleClick }, { text: "Delete" }];

  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const onConfirmDelete = async () => {
    setShowDialog(false);
    setSelectedItems([]);
    dispatch(deleteMachine({ machineIds: selectedItems })).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload && res.payload.status === 200) {
        dispatch(getMachines()).then((res) => {
          toast.success("Asset(s) deleted successfully!");
        });
      } else return toast.error("Something went wrong!");
    });
  };

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Locations"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <div>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
              <Typography>Please press "Confirm Delete" to delete the selected item(s).</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setShowDialog(false)}>Cancel</Button>
              <Button sx={{}} onClick={onConfirmDelete}>
                Confirm Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <Box>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate(`/${Links.ADD_LOCATION}/`)}
            >
              Add
            </Button>
          </Box>
          {/* <Box>
            <Button
              disabled={!selectedItems.length}
              startIcon={<DeleteIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => setShowDialog(true)}
            >
              Delete
            </Button>
          </Box> */}
          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />
        </Stack>
        <DataGridTable
          checkboxSelection={true}
          rowId={"id"}
          data={machineDepartments.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={tableGrids}
          onSelectRow={setSelectedItems}
        />
      </Box>
    </div>
  );
};
export default Locations;
