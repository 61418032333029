import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Autocomplete, TextField, Stack, IconButton, OutlinedInput, InputAdornment, Box, useTheme, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";
import { getAllTemplates } from "features/service/service.slice";
import DataGridTable from "component/DataGridTable";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";

const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const { allTemplates, appConstants } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);
  const [vendorsList, setVendorsList] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (allTemplates.data.length && !allTemplates.isLoading) setTemplatesList(allTemplates.data);
  }, [allTemplates.data]);

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length) {
      const vendors = appConstants.customers
        ?.filter((user) => user.customerType === appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR)
        .sort((a, b) => a.customerName.localeCompare(b.customerName))
        .map((vendor) => ({
          ...vendor,
          label: vendor.customerName,
          value: vendor.id,
        }));
      setVendorsList(vendors);
    }
  }, [appConstants]);

  const tableGrids = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      minWidth: 60,
      flex: 0.4,
      valueGetter: (params) =>
        appConstants.customers.find((item) => item.id === parseInt(params.row[params.field]))?.customerName || "Internal",
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 60,
      flex: 0.4,
      valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    },
    {
      minWidth: 60,
      flex: 0.2,
      sortable: false,
      filterable: false,
      renderCell: (args) => (
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_TEMPLATE}/` + args.row.id)}>
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      ),
    },
  ];
  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Templates"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} alignItems={"center"} my={2} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate(`/${Links.CREATE_TEMPLATE}`)}
            >
              New
            </Button>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
          <Autocomplete
            disablePortal
            options={vendorsList}
            onChange={(e, newValue, reason) => {
              if (reason === "clear") setTemplatesList(allTemplates.data);
              else setTemplatesList([...allTemplates.data.filter((template) => template.vendor === newValue.id)]);
            }}
            sx={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label="Filter by vendor" />}
          />
        </Stack>
        <DataGridTable
          rowId={"id"}
          data={templatesList.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={tableGrids}
        />
      </Box>
    </div>
  );
};

export default Templates;
