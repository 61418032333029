import React, { useState, useEffect } from "react";
// import { FiShoppingCart } from 'react-icons/fi';
// import { BsChatLeft } from 'react-icons/bs';
// import { RiNotification3Line } from 'react-icons/ri';
// import { MdKeyboardArrowDown } from 'react-icons/md';
import styled from "@emotion/styled";
import { useDispatch, useSelector } from "react-redux";

import "../stylesheet/navBarStyles.scss";
import { Menu as MenuIcon, ArrowDropDownOutlined } from "@mui/icons-material";
import { AppBar, Button, Box, Typography, IconButton, Toolbar, Menu, MenuItem, useTheme, Switch } from "@mui/material";
import { logout } from "features/auth/auth.slice";
import { Constants } from "utils/Constants";
import { setMode, reset } from "features/global/global.slice";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";

const LeftSide = styled("Box")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
const RightSide = styled("Box")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const BoxContainer = styled("Box")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Navbar = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const { appConstants } = useSelector((state) => state.service);
  const [anchorEl, setAnchorEl] = useState(null);

  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => {
    // /login
    setAnchorEl(null);
  };
  const getUserInfo = () => {
    const userFullName = appConstants?.appUsers?.find((users) => users.userName == user.username);
    return userFullName ? `${userFullName.name} (${user?.username})` : "N.A";
  };
  const toggleTheme = () => {
    // Toggle the theme mode between dark and light
    // setDarkTheme(!darkTheme);
    dispatch(setMode());
  };

  // useEffect(() => {
  //     // Update the theme mode when the state changes
  //     dispatch(setMode(darkTheme ? 'dark' : 'light'));
  // }, [darkTheme]);

  return (
    <AppBar
      position="fixed"
      sx={{
        position: "fixed",
        width: { sm: `calc(100%-${props.drawerWidth}px)` },
        ml: { sm: `${props.drawerWidth}px` },
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <LeftSide>
          {/* <IconButton>
                        <MenuIcon sx={{color:"white"}}/>
                    </IconButton> */}
        </LeftSide>
        <RightSide>
          <BoxContainer gap="1.5rem">
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography variant="body2" sx={{}}>
                Dark Mode
              </Typography>
              <Switch
                checked={theme.palette.mode === "dark"}
                onChange={toggleTheme}
                color="secondary"
                icon={<LightModeIcon />}
                checkedIcon={<DarkModeIcon />}
              />
            </Box>
            <BoxContainer>
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  textTransform: "none",
                  gap: "1rem",
                }}
              >
                <Box textAlign="left">
                  <Typography fontWeight="bold" fontSize="0.85rem" sx={{ textTransform: "capitalize" }}>
                    {getUserInfo()}
                  </Typography>
                  <Typography fontSize="0.75rem" sx={{}}>
                    {user && "roles" in user ? Constants.userRoles[user.roles[0]] : "N.A"}
                  </Typography>
                </Box>
                <ArrowDropDownOutlined sx={{ fontSize: "25px" }} />
              </Button>
              <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                <MenuItem
                  onClick={() => {
                    dispatch(reset());
                    dispatch(logout());
                    // navigate("/login")
                  }}
                >
                  Log Out
                </MenuItem>
              </Menu>
            </BoxContainer>
          </BoxContainer>
        </RightSide>
      </Toolbar>
    </AppBar>
  );
};
export default Navbar;
