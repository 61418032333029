import api from "app/api";
import multiInstance from "app/multiPartApi";
import moment from "moment";

const getServiceTicketsByStatusListAndDateCreatedInDaysV1 = async (payload) => {
  const { data } = await api.post("/serviceapi/getServiceTicketsByStatusListAndDateCreatedInDaysV1?days=" + payload.dateRange, {
    statusList: payload.statusList,
  });
  return data;
};

const getServiceTicketsByStatusList = async (payload) => {
  const { data } = await api.post("/serviceapi/getServiceTicketsByStatusList", {
    statusList: payload.statusList,
  });
  return data;
};

const getTimespentByServicetTicketId = async (payload) => {
  const response = await api.post("/serviceapi/getTimespentByServicetTicketId?days=" + payload);
  return response;
};

const addMachine = async (payload) => {
  const response = await api.post("/serviceapi/addMachine", payload);
  return response;
};

const readSpareParts = async () => {
  const response = await api.get("/serviceapi/readSpareParts");
  return response;
};

const addSpareParts = async (payload) => {
  const response = await multiInstance.post("/serviceapi/createSparePart", payload);
  return response;
};

const updateSpareParts = async (payload) => {
  const response = await multiInstance.post("/serviceapi/updateSparePart", payload);
  return response;
};

const deleteSpareParts = async (payload) => {
  const response = await api.post("/serviceapi/deleteSparePart", payload);
  return response;
};

const updateMachine = async (payload) => {
  const response = await api.post("/serviceapi/updateMachine", payload);
  return response;
};

const addImageOrVideoToServiceTicket = async (payload) => {
  const response = await multiInstance.post("serviceapi/addImageOrVideoToServiceTicket", payload);
  return response;
};

const readServiceTicketMessages = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTicketMessagesByServiceTicketId?id=" + payload);
  return response;
};

const updateNotesForTicket = async (payload) => {
  const response = await api.post("serviceapi/addTextMessageToServiceTicket", payload);
  return response;
};

const readServiceTimeLog = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTimeLog?id=" + payload);
  return response;
};

const getMachineDepartments = async () => {
  const response = await api.get("/serviceapi/machineDepartments");
  return response;
};

const deleteMachine = async (payload) => {
  const response = await api.post("/serviceapi/deleteMachine", payload);
  return response;
};

const getServiceTicketsByDepartmentAndDateCreatedInDays = async (payload) => {
  const response = await api.post("/serviceapi/getServiceTicketsByDepartmentAndDateCreatedInDays?days=" + payload);
  return response;
};

const readServiceTicketsByMachineId = async (payload) => {
  const response = await api.post("/serviceapi/readServiceTicketsByMachineId?id=" + payload);
  return response;
};

const getMachinesNotWorking = async () => {
  const response = await api.get("/serviceapi/getMachinesNotWorkingDashboard");
  return response;
};

const getActiveMachinesByStatus = async () => {
  const response = await api.get("/serviceapi/getActiveMachinesByStatus");
  return response;
};

const getOverdueServiceTickets = async () => {
  const response = await api.get("/serviceapi/getOverdueServiceTickets");
  return response.data;
};

const getOutOfServiceMachinesByCategory = async () => {
  const response = await api.get("/serviceapi/getOutOfServiceMachinesByCategory");
  return response.data;
};

const engineerReportsById = async (payload) => {
  const response = await api.get("/serviceapi/getServiceEngineerTickets?svcEngrId=" + payload);
  return response;
};
const readAsyncStorageValues = async () => {
  const response = await api.get("/serviceapi/readAsyncStorageValues");
  return response;
};

const getMachines = async () => {
  const { data } = await api.get("/serviceapi/readMachinesByCustomerId?id=5284");
  return data;
};

const createMachineLocation = async (payload) => {
  const { data } = await api.post("/serviceapi/createMachineLocation", payload);
  return data;
};

const updateMachineLocation = async (payload) => {
  const { data } = await api.post("/serviceapi/updateMachineLocation", payload);
  return data;
};

const getMonthlyMachineBreakdownTime = async () => {
  const response = await api.get("/serviceapi/getMonthlyMachineBreakdownTime");
  return response;
};
const getWeeklyMachineBreakdownTime = async () => {
  const response = await api.get("/serviceapi/getWeeklyMachineBreakdownTime");
  return response;
};

const getMonthlyBreakdownHoursPercent = async () => {
  const response = await api.get("/serviceapi/getMonthlyBreakdownHoursPercent");
  const modified = response && response?.data ? response?.data.reverse() : [];
  return { ...response, data: modified };
};

const getWeeklyBreakdownHoursPercent = async () => {
  const response = await api.get("/serviceapi/getWeeklyBreakdownHoursPercent");
  const modified = response?.data.map((item, index) => ({
    ...item,
    baseline: 3,
    week:
      index === 0
        ? `${moment(new Date()).startOf("isoWeek").format("ddd DD MMM")} - ${moment(new Date()).endOf("isoWeek").format("ddd DD MMM")}`
        : item.week,
  }));
  return { ...response, data: modified.reverse() };
};

const readHoursDataByDays = async (payload) => {
  const response = await api.get("/serviceapi/getTimespentByDepartment?days=" + payload);
  return response;
};
const getServiceTicketById = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTicket?id=" + payload);
  return response;
};

const createServiceTicket = async (payload) => {
  const response = await api.post("/serviceapi/createServiceTicket", payload);
  return response;
};
const updateServiceTicket = async (payload) => {
  const response = await api.post("/serviceapi/updateServiceTicket", payload);
  return response;
};

const getCompletedReportPdf = async (payload) => {
  const response = await api.get("/serviceapi/readReportPathByServiceTicketId?serviceTicketId=" + payload);
  return response;
};

const createTemplate = async (payload) => {
  const response = await api.post("/serviceapi/createTemplate", payload);
  return response;
};

const updateTemplate = async (payload) => {
  const response = await api.post("/serviceapi/updateTemplate", payload);
  return response;
};

const getAllTemplates = async () => {
  const response = await api.get("/serviceapi/getAllTemplates");
  return response;
};

const getTechnicianLoggedTime = async (payload) => {
  const response = await api.get(`/serviceapi/getTechnicianLoggedTime?logDate=${payload.logDate}`);
  return response.data;
};

const createMachineModel = async (payload) => {
  const response = await api.post("/serviceapi/createMachineModel", payload);
  return response;
};

const updateMachineModel = async (payload) => {
  const response = await api.post("/serviceapi/updateMachineModel", payload);
  return response;
};

const Service = {
  getAllTemplates,
  createTemplate,
  updateTemplate,
  engineerReportsById,
  readAsyncStorageValues,
  getServiceTicketsByStatusList,
  getServiceTicketsByStatusListAndDateCreatedInDaysV1,
  getServiceTicketsByDepartmentAndDateCreatedInDays,
  getTimespentByServicetTicketId,
  getMachines,
  readHoursDataByDays,
  addMachine,
  updateMachine,
  readServiceTicketsByMachineId,
  deleteMachine,
  getMachineDepartments,
  readSpareParts,
  addSpareParts,
  deleteSpareParts,
  updateSpareParts,
  getMachinesNotWorking,
  getServiceTicketById,
  createServiceTicket,
  updateServiceTicket,
  addImageOrVideoToServiceTicket,
  getCompletedReportPdf,
  readServiceTicketMessages,
  updateNotesForTicket,
  readServiceTimeLog,
  getActiveMachinesByStatus,
  getOverdueServiceTickets,
  getOutOfServiceMachinesByCategory,
  getMonthlyMachineBreakdownTime,
  getWeeklyMachineBreakdownTime,
  getMonthlyBreakdownHoursPercent,
  getWeeklyBreakdownHoursPercent,
  createMachineLocation,
  updateMachineLocation,
  getTechnicianLoggedTime,
  createMachineModel,
  updateMachineModel,
};

export default Service;
