import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  CircularProgress,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Typography,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Tooltip,
  Link,
  useTheme,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";
// import { sparesGrid } from "data/dataTovisualize";
import { deleteSpareParts, readSpareParts } from "features/service/service.slice";
import "../../../App.css";
import "../../../stylesheet/charts.css";
import { useNavigate } from "react-router-dom";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataGridTable from "component/DataGridTable";
import AuthorizedImage from "component/AuthorizedImage";
import QRCodeIcon from "@mui/icons-material/QrCode";
import jsPDF from "jspdf";
import { LinearProgress } from "@mui/material";
import { exportToCsv } from "utils/utilFunctions";

const SpareParts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { spareParts, isLoading } = useSelector((state) => state.service);
  const { appConstants } = useSelector((state) => state.service);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [params, setParams] = useState(null);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [hideProgress, setHideProgress] = useState(true);
  const theme = useTheme();
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(readSpareParts());
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const gridSparePartsAction = (args) => {
    const handleQRCodeClick = async () => {
      const data = `
partCode:${args.row.partCode}
maker:${args.row.maker}
description:${args.row.description}`;

      const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

      try {
        const response = await fetch(qrCodeApiUrl);
        if (!response.ok) {
          console.error("Failed to generate QR code.");
          return;
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        const custName = appConstants?.customers
          ?.filter((customer) => customer.id == 5284)[0]
          ?.customerName.split(" ")
          .join("_");
        downloadLink.download = `${custName}_qr_code_sparePart_${args.row.partCode}.png`;

        downloadLink.click();

        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    return (
      <div>
        <Link component="button" variant="body2" sx={{ mr: 2.5 }} onClick={handleQRCodeClick}>
          <Tooltip title="Download QR">
            <QRCodeIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </Link>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_SPARE_PART}/` + args.row.id)}>
          <Tooltip title="View">
            <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_SPARE_PART}/` + args.row.id, { state: { enableEditing: true } })}>
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  };

  const renderImageCell = (params) => {
    return (
      <div
        onMouseEnter={() => {
          if (params.row.image?.length > 0) {
            setParams(params);
          }
        }}
        onMouseLeave={() => {
          setParams(null);
        }}
      >
        <Tooltip>{params.row.image?.length > 0 ? <AuthorizedImage path={params.row.image} width={50} height={50} /> : <span />}</Tooltip>
      </div>
    );
  };

  const sparesGrid = [
    {
      field: "image",
      headerName: "Image",
      width: 80,
      disableExport: true,
      renderCell: renderImageCell,
    },
    {
      field: "maker",
      headerName: "Maker",
      minWidth: 50,
      flex: 0.3,
      // renderCell: renderImageCell,
    },
    {
      field: "partCode",
      headerName: "Part Code",
      minWidth: 50,
      flex: 0.3,
      // renderCell: renderImageCell,
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 50,
      flex: 0.2,
      // renderCell: renderImageCell,
    },
    {
      field: "location",
      headerName: "Location",
      minWidth: 50,
      flex: 0.3,
      // renderCell: renderImageCell,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 50,
      flex: 0.4,
      // renderCell: renderImageCell,
    },
    {
      field: "currentStock",
      headerName: "Current Stock",
      minWidth: 50,
      flex: 0.2,
      // renderCell: renderImageCell,
    },
    {
      field: "issued",
      headerName: "Issued",
      minWidth: 50,
      flex: 0.2,
      // renderCell: renderImageCell,
    },
    {
      field: "returned",
      headerName: "Returned",
      minWidth: 50,
      flex: 0.2,
      // renderCell: renderImageCell,
    },

    {
      minWidth: 50,
      flex: 0.4,
      sortable: false,
      filterable: false,
      renderCell: gridSparePartsAction,
    },
  ];

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id,Maker,Part code, Unit, Location, Description, Current stock ,Issued,Returned"];
    const keys = ["id", "maker", "partCode", "unit", "location", "description", "currentStock", "issued", "returned"];
    exportToCsv(headers, keys, spareParts, "SpareParts");
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const onConfirmDelete = () => {
    setShowDialog(false);
    setSelectedItems([]);
    dispatch(deleteSpareParts({ modelPartIds: selectedItems })).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload && res.payload.status === 200) {
        dispatch(readSpareParts()).then((res) => {
          toast.success("Spare part(s) deleted successfully!");
        });
      } else return toast.error("Something went wrong!");
    });
  };

  const generateQRCodeImage = async (data) => {
    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;
    const response = await fetch(qrCodeApiUrl);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  const generatePDF = async () => {
    const doc = new jsPDF();

    const qrCodeWidth = 76.2;
    const qrCodeHeight = 76.2;
    const qrCodesPerRow = 2;
    const rowsPerPage = 3;
    const marginLeft = 10;
    const marginTop = 10;
    const spacingX = 90;
    const spacingY = 90;
    const totalQRCodeCount = spareParts.length;
    let currentQRCodeCount = 0;

    setHideProgress(false);

    for (let i = 0; i < spareParts.length; i += qrCodesPerRow * rowsPerPage) {
      if (i !== 0) {
        doc.addPage();
      }

      for (let row = 0; row < rowsPerPage; row++) {
        for (let col = 0; col < qrCodesPerRow; col++) {
          const currentIndex = i + row * qrCodesPerRow + col;
          if (currentIndex < spareParts.length) {
            const sparePart = spareParts[currentIndex];
            const formattedData = `
partCode: ${sparePart.partCode}
maker: ${sparePart.maker}
description: ${sparePart.description}
            `;

            const qrCodeImageUrl = await generateQRCodeImage(formattedData);
            const x = marginLeft + col * spacingX;
            const y = marginTop + row * spacingY;

            doc.text(sparePart.partCode, x, y + qrCodeHeight + 5);
            doc.addImage(qrCodeImageUrl, "PNG", x, y, qrCodeWidth, qrCodeHeight);

            currentQRCodeCount++;
            const progress = (currentQRCodeCount / totalQRCodeCount) * 100;
            setDownloadProgress(progress);
          }
        }
      }
    }
    const custName = appConstants?.customers
      ?.filter((customer) => customer.id == 5284)[0]
      ?.customerName.split(" ")
      .join("_");
    doc.save(`${custName}_qr_codes_spareParts_${moment().format("DD-MM-YYYY_HH:mm")}.pdf`);

    setDownloadProgress(100);
    setHideProgress(true);
  };

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Spare Parts"]} />
      </Stack>
      <div>
        <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <Typography>Please press "Confirm Delete" to delete the selected item(s).</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowDialog(false)}>Cancel</Button>
            <Button onClick={onConfirmDelete} color="primary">
              Confirm Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Box sx={{ mx: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" my={2}>
          <Stack direction="row" gap={1} alignItems="center">
            <Box>
              <Button
                startIcon={<AddIcon />}
                sx={{ height: "self" }}
                autoCapitalize="none"
                variant="contained"
                onClick={() => navigate(`/${Links.ADD_SPARE_PART}/`)}
              >
                Add
              </Button>
            </Box>
            <Box>
              <Button
                disabled={!selectedItems.length}
                startIcon={<DeleteIcon />}
                sx={{ height: "self" }}
                autoCapitalize="none"
                variant="contained"
                onClick={() => setShowDialog(true)}
              >
                Delete
              </Button>
            </Box>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>

          <Box sx={{ alignItems: "center" }}>
            <Button
              startIcon={<DownloadIcon />}
              sx={{ height: "self", mr: 2 }}
              autoCapitalize="none"
              variant="contained"
              onClick={downloadCsv}
            >
              Export to csv
            </Button>
            <Button
              startIcon={<DownloadIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={generatePDF}
              disabled={!hideProgress}
            >
              Download QR
            </Button>
            {downloadProgress !== null && !hideProgress && (
              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={downloadProgress} />
                <Typography variant="body2" color="textSecondary">{`${downloadProgress.toFixed(2)}%`}</Typography>
              </Box>
            )}
          </Box>
        </Stack>
        <DataGridTable
          checkboxSelection={true}
          rowId={"id"}
          data={spareParts.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={sparesGrid}
          onSelectRow={setSelectedItems}
        />
        {params && (
          <div
            style={{
              position: "fixed",
              top: "55%",
              left: "31%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
              border: "1px solid black",
              padding: "1px",
              borderRadius: "5px",
            }}
          >
            <AuthorizedImage path={params.row.image} width={400} height={400} />
            <div style={{ marginTop: "2px", backgroundColor: "#ededed", textAlign: "center" }}>
              <Typography variant="h6">{`${params.row.maker}[${params.row.partCode}]`}</Typography>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};
export default SpareParts;
