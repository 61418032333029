import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Card, Stack, List, Typography, CircularProgress, IconButton, Paper, Autocomplete, TextField, Grid } from "@mui/material";
import { readServiceTimeLog, readAsyncStorageValues } from "features/service/service.slice";
import moment from "moment";

const Timelogs = ({ ticketId }) => {
  const dispatch = useDispatch();
  const { serviceTimeLogs, appConstants, isLoading } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(readServiceTimeLog(ticketId));
  }, [dispatch]);

  const minutes_to_hhmm = (numberOfMinutes) => {
    //calculate hours
    var hh =
      numberOfMinutes.years() * (365 * 24) + numberOfMinutes.months() * (30 * 24) + numberOfMinutes.days() * 24 + numberOfMinutes.hours();
    //get minutes
    var mm = numberOfMinutes.minutes();
    //return total time in hh:mm format
    return hh + "h " + mm + " m";
  };

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      <Typography variant="h5" fontWeight={600} mb={1}>
        Timelogs
      </Typography>
      <Stack mb={1}>
        <List
          sx={{
            overflow: "auto",
            maxHeight: 500,
          }}
        >
          {serviceTimeLogs.length
            ? serviceTimeLogs.map((timeLog) => (
                <Paper
                  sx={{
                    mt: 2,
                    p: 1,
                    border: "1px solid #ddd",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
                    // background: "rgb(247,247,247)",
                    // background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    borderRadius: 2,
                  }}
                >
                  <Stack flexDirection="row">
                    <Typography>
                      Hours :{" "}
                      {timeLog.endTime
                        ? minutes_to_hhmm(
                            moment.duration(
                              moment(moment(timeLog.endTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm").diff(
                                moment(moment(timeLog.startTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm"),
                                "minutes"
                              ),
                              "minutes"
                            )
                          )
                        : " -"}
                    </Typography>
                    <Typography ml={2}>
                      {appConstants && Object.keys(appConstants).length
                        ? appConstants.appUsers.find((user) => user.userName === timeLog.serviceEngineer).name
                        : ` [${timeLog.serviceEngineer}]`}
                      {` [${timeLog.serviceEngineer}]`}
                    </Typography>
                  </Stack>
                  <Typography>Start time : {timeLog?.startTime ? moment(timeLog.startTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                  <Typography>End time : {timeLog?.endTime ? moment(timeLog.endTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                </Paper>
              ))
            : "No timelogs."}
        </List>
      </Stack>
    </div>
  );
};

export default Timelogs;
