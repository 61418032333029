import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Typography, Paper, Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Label, ResponsiveContainer } from "recharts";

import { getServiceTicketsByStatusListAndDateCreatedInDaysV1, readAsyncStorageValues } from "features/service/service.slice";
import { Constants } from "utils/Constants";
import { Links } from "utils/pageLinkNames";

const ChartTicketsByStatus = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { serviceTicketsByStatusListAndDateCreatedInDays, appConstants } = useSelector((state) => state.service);
  const [ticketsByStatus, setTicketsByStatus] = useState([]);
  const [days, setDays] = useState(1);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length) {
      dispatch(
        getServiceTicketsByStatusListAndDateCreatedInDaysV1({
          statusList: [
            appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
            appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
            appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
          ],
          dateRange: days,
        })
      );
    }
  }, [dispatch, appConstants, days]);

  useEffect(() => {
    if (appConstants && serviceTicketsByStatusListAndDateCreatedInDays) {
      const data = [];
      for (const [key, value] of Object.entries(serviceTicketsByStatusListAndDateCreatedInDays)) {
        data.push({
          status: key,
          tickets: value,
        });
      }
      setTicketsByStatus(data);
    }
  }, [serviceTicketsByStatusListAndDateCreatedInDays]);

  const goToTimeSpentByServiceTickets = (days) => {
    navigate(`/${Links.TIME_SPENT_BY_SERVICE_TICKETS}`, {
      state: { initialDateRange: days },
    });
  };

  return (
    <Paper elevation={8} sx={{ padding: 1 }}>
      <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Tickets by Status
      </Typography>
      <Stack direction={"row"} spacing={2} sx={{ padding: 1 }}>
        <Button
          fullWidth
          size="small"
          variant={days === 1 ? "contained" : "outlined"}
          onClick={() => setDays(1)}
          sx={[{ fontWeight: "bold" }, days === 1 ? {} : { backgroundColor: "#fff" }]}
        >
          1 Day
        </Button>
        <Button
          fullWidth
          size="small"
          variant={days === 7 ? "contained" : "outlined"}
          onClick={() => setDays(7)}
          sx={[{ fontWeight: "bold" }, days === 7 ? {} : { backgroundColor: "#fff" }]}
        >
          1 Week
        </Button>
        <Button
          fullWidth
          size="small"
          variant={days === 30 ? "contained" : "outlined"}
          onClick={() => setDays(30)}
          sx={[{ fontWeight: "bold" }, days === 30 ? {} : { backgroundColor: "#fff" }]}
        >
          1 Month
        </Button>
      </Stack>
      <ResponsiveContainer width="99%" aspect={1.08}>
        <ComposedChart
          data={ticketsByStatus}
          margin={{
            top: 20,
            right: 10,
            bottom: 10,
            left: 10,
          }}
          onClick={() => goToTimeSpentByServiceTickets(days)}
          cursor="pointer"
        >
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="status" stroke={mode === "dark" ? "#fff" : "#000"}></XAxis>
          <YAxis allowDecimals={false} stroke={mode === "dark" ? "#fff" : "#000"}>
            <Label
              style={{
                textAnchor: "middle",
              }}
              angle={270}
              value={"Count"}
              stroke={mode === "dark" ? "#fff" : "#7F7F7F"}
            />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="tickets"
            name="Tickets"
            barSize={50}
            fill={theme.palette.secondary[300]}
            label={{ position: "top" }}
            isAnimationActive={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ChartTicketsByStatus;
