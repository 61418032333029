import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, OutlinedInput, IconButton, InputAdornment, Button, Stack, Box, Tooltip, useTheme } from "@mui/material";
import { Search, Download as DownloadIcon, ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";

import { getAllUsers } from "features/appUsers/appUsers.slice";
import "../../App.css";
import "../../stylesheet/charts.css";
import { useNavigate } from "react-router-dom";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import { exportToCsv } from "utils/utilFunctions";

const Users = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { allUsers, isLoading } = useSelector((state) => state.appUsers);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState("");

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["User Name,Name, Email, Mobile Number, Role,Department, Active"];
    const keys = ["userName", "name", "email", "userMobileNumber", "userRole", "department", "enabled"];
    exportToCsv(headers, keys, allUsers, "Users");
  };
  const gridUserAction = (args) => {
    return (
      <div>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_USER}/` + args.row.userId)}>
          <Tooltip title="View">
            <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_USER}/` + args.row.userId, { state: { enableEditing: true } })}>
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  };
  const userGrid = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "userMobileNumber",
      headerName: "Mobile Number",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "userRole",
      headerName: "Role",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "department",
      headerName: "Department",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "userName",
      headerName: "User Name",
      minWidth: 30,
      flex: 0.2,
    },
    {
      field: "enabled",
      headerName: "Active",
      minWidth: 20,
      flex: 0.1,
      valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    },
    {
      headerName: "",
      minWidth: 40,
      flex: 0.2,
      renderCell: gridUserAction,
      sortable: false,
      filterable: false,
    },
  ];
  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Users"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" my={2}>
          <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
            <Box>
              <Button
                startIcon={<AddIcon />}
                sx={{ height: "self" }}
                autoCapitalize="none"
                variant="contained"
                onClick={() => navigate(`/${Links.ADD_USER}/`)}
              >
                Add
              </Button>
            </Box>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
          <Button
            startIcon={<DownloadIcon />}
            sx={{ height: "self", mr: 2 }}
            autoCapitalize="none"
            variant="contained"
            onClick={downloadCsv}
          >
            Export to csv
          </Button>
        </Stack>
        <DataGridTable
          rowId={"userId"}
          data={allUsers.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={userGrid}
        />
      </Box>
    </div>
  );
};
export default Users;
