import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getMachines, readAsyncStorageValues, getServiceTicketById, updateServiceTicket } from "features/service/service.slice";
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  useTheme,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  IconButton,
} from "@mui/material";
import moment from "moment";
import TicketNotes from "pages/Service/TicketNotes";
import Gallery from "pages/Gallery";
import Timelogs from "pages/Service/Timelogs";
import CreateUpdateTicket from "pages/Service/CreateUpdateTicket";
import CloseIcon from "@mui/icons-material/Close";

import { toast } from "react-toastify";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";

const ServiceTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { ticketId } = useParams();
  const { state } = useLocation();
  const [machineInfo, setMachineInfo] = useState({ manufacturer: "", model: "", serialNumber: "" });
  const [editTicketEnabled, setEditTicketEnabled] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogAction, setShowDialogAction] = useState(false);
  const [showDialogVendor, setShowDialogVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);

  const { machinesData, serviceTicketById, appConstants, isLoading } = useSelector((state) => state.service);
  console.log(serviceTicketById);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getMachines());
    if (ticketId) dispatch(getServiceTicketById(ticketId));
  }, [dispatch, editTicketEnabled]);

  useEffect(() => {
    if (Object.keys(appConstants).length && Object.keys(serviceTicketById).length && machinesData.length) {
      const machine = machinesData.find((item) => item.id === serviceTicketById.machine?.id);
      if (machine) setMachineInfo(machine);
      // if (ticketId && state?.enableEditing) setEditTicketEnabled(true);
    }
    if (!ticketId || state?.enableEditing) setEditTicketEnabled(true);
  }, [dispatch, appConstants, serviceTicketById, machinesData]);

  const assigneeInfo = (assignee) => {
    const assigneUser = appConstants.appUsers?.find((user) => user.userId === assignee.id);
    return assigneUser ? `${assigneUser.name} (${assigneUser.userName})` : "-";
  };

  const onPressConfirmApprove = () => {
    const updateObj = {
      dateCreated: serviceTicketById.dateCreated,
      status: appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
      ticketCategory: serviceTicketById.ticketCategory,
      scheduledDate: moment(serviceTicketById.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      machineId: serviceTicketById.machine.id,
      assignees: serviceTicketById.assignees.map((a) => a.id) || [],
      serviceTicketId: serviceTicketById.id,
      priority: serviceTicketById.priority,
      dueDate: serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("YYYY-MM-DDTHH:mm:ssZ") : null,
      problemCategory: serviceTicketById.problemCategory,
      vendor: serviceTicketById.vendor?.id || null,
      description: serviceTicketById.description,
    };
    dispatch(updateServiceTicket(updateObj)).then((updateResponse) => {
      if (!updateResponse) toast.error("Something went wrong!");
      if (updateResponse && updateResponse.error) toast.error("Please try again!");
      if (updateResponse && updateResponse.payload.data && updateResponse.payload.status === 200) {
        dispatch(getServiceTicketById(serviceTicketById.id));
        toast.success("Ticket approved successfully!");
      } else toast.error("Something went wrong!");
    });
    setShowDialog(false);
  };

  const onPressConfirmApproveAction = (action) => {
    let updateObj = {
      dateCreated: serviceTicketById.dateCreated,
      ticketCategory: serviceTicketById.ticketCategory,
      scheduledDate: moment(serviceTicketById.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      machineId: serviceTicketById.machine.id,
      assignees: serviceTicketById.assignees.map((a) => a.id) || [],
      serviceTicketId: serviceTicketById.id,
      priority: serviceTicketById.priority,
      dueDate: serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("YYYY-MM-DDTHH:mm:ssZ") : null,
      problemCategory: serviceTicketById.problemCategory,
      vendor: serviceTicketById.vendor?.id || null,
      description: serviceTicketById.description,
    };
    let message;
    if (action == "spares") {
      if (serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED) {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED_PENDING;
        message = "Ticket status is changed to spares recommended pending";
      } else {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED_PENDING;
        message = "Ticket status is changed to spares required pending";
      }
    } else if (action == "assigned") {
      updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED;
      message = "Ticket status is changed to 'Assigned'";
    } else if (action == "vendor") {
      if (selectedVendor) {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED;
        updateObj.vendor = selectedVendor ? appConstants.customers.filter((user) => user.customerName === selectedVendor)[0].id : null;
        message = "Ticket status is changed to Assigned";
      } else {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_PENDING;
        message = "Ticket status is changed to vendor support pending";
      }
    }
    dispatch(updateServiceTicket(updateObj)).then((updateResponse) => {
      if (!updateResponse) toast.error("Something went wrong!");
      if (updateResponse && updateResponse.error) toast.error("Please try again!");
      if (updateResponse && updateResponse.payload.data && updateResponse.payload.status === 200) {
        dispatch(getServiceTicketById(serviceTicketById.id));
        toast.success(message);
      } else toast.error("Something went wrong!");
    });
    setShowDialogAction(false);
    setShowDialogVendor(false);
    setSelectedVendor("");
  };

  const assigner = appConstants?.appUsers?.find((user) => user.userId === serviceTicketById?.assigner?.id);

  const ticketDetails = () => (
    <>
      {isLoading && appConstants && Object.keys(appConstants).length && Object.keys(serviceTicketById).length ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Stack sx={{ flexWrap: "wrap" }}>
          <Stack flexDirection="row" justifyContent={"space-between"} sx={{ flex: 1, flexWrap: "wrap" }}>
            <Stack sx={{ flex: 2, mb: 1, gap: 1 }}>
              <Typography>Ticket : {ticketId}</Typography>
              <Typography sx={{ fontWeight: "bold" }}>{serviceTicketById.safetyTicket ? "Safety Ticket" : null}</Typography>
              <Typography>Created : {moment(serviceTicketById.dateCreated).format("DD MMM YYYY")}</Typography>
              <Typography>Scheduled : {moment(serviceTicketById.scheduledDate).format("DD MMM YYYY")}</Typography>
              <Typography>
                Due date : {serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("DD MMM YYYY") : "N.A"}
              </Typography>
              <Typography>Priority : {serviceTicketById.priority || "N.A"}</Typography>
              <Typography>Creator: {assigner ? `${assigner.name} (${assigner.userRole})` : null}</Typography>
              <Typography>Service ticket category : {serviceTicketById.ticketCategory}</Typography>
            </Stack>
            <Stack sx={{ flex: 2, mb: 1, gap: 1 }}>
              <Typography>Status : {serviceTicketById.status}</Typography>
              {appConstants &&
              Object.keys(appConstants).length &&
              serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.COMPLETED ? (
                <Typography>
                  Completed on : {serviceTicketById.closeDate ? moment(serviceTicketById.closeDate).format("DD MMM YYYY") : "N.A"}
                </Typography>
              ) : null}
              <Typography>Manufacturer : {machineInfo.manufacturer || "N.A"}</Typography>
              <Typography>Model : {machineInfo.model || "N.A"}</Typography>
              <Typography>Serial number : {machineInfo.serialNumber || "N.A"}</Typography>
              <Typography>Maintenance code : {machineInfo.maintenanceCode || "N.A"}</Typography>
            </Stack>
            <Stack sx={{ flex: 2, mr: 2 }}>
              <Stack sx={{ mb: 1, gap: 1 }}>
                <Typography>Problem category : {serviceTicketById.problemCategory?.slice(1, -1) || "N.A"}</Typography>
                <Typography>
                  Vendor :
                  {appConstants && appConstants.customers && serviceTicketById.vendor?.id
                    ? appConstants.customers.find((user) => user.id === serviceTicketById.vendor?.id)?.customerName
                    : " Internal"}
                </Typography>
                <Stack flexDirection="row" flexWrap={"wrap"}>
                  <Typography mr={1}>Technician(s) :</Typography>
                  <Stack flexDirection="row" flexWrap={"wrap"} sx={{}}>
                    {serviceTicketById.assignees?.length
                      ? serviceTicketById.assignees?.map((assignee, index) => <Typography>{assigneeInfo(assignee)}</Typography>)
                      : "Not Assigned"}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            {appConstants && Object.keys(appConstants).length ? (
              <Stack spacing={2} sx={{ flex: 0.5, alignItems: "flex-end" }}>
                {(serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED ||
                  serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED) &&
                serviceTicketById.ticketCategory !== appConstants.allTypes.SERVICE_CATEGORY.INSPECTION ? (
                  <Button sx={{ height: "self" }} variant="contained" onClick={() => navigate(`/${Links.REPORTS}/` + serviceTicketById.id)}>
                    View report
                  </Button>
                ) : null}
                {serviceTicketById.status !== appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED &&
                serviceTicketById.status !== appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED ? (
                  <Button sx={{ height: "self" }} variant="contained" onClick={() => setEditTicketEnabled(true)}>
                    {editTicketEnabled ? "Save" : "Edit"}
                  </Button>
                ) : null}
                {serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED ? (
                  <Button sx={{ height: "self" }} variant="contained" onClick={() => setShowDialog(true)}>
                    Approve
                  </Button>
                ) : null}
                {serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED ||
                serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED ||
                serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED ? (
                  <Stack>
                    <Button sx={{ height: "self" }} variant="contained" onClick={() => setShowDialogAction(true)}>
                      Approve
                    </Button>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED
                        ? "Vendor Support Request"
                        : serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED
                        ? "Spares Recommended Request"
                        : serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED
                        ? "Spares Required Request"
                        : null}
                    </Typography>
                  </Stack>
                ) : null}
              </Stack>
            ) : null}
          </Stack>
          <Typography>Description : {serviceTicketById.description}</Typography>
        </Stack>
      )}
    </>
  );

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Service ticket", ticketId ? "Ticket - " + ticketId : ""]} />
      </Stack>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <Typography mt={1}>
            Click <span style={{ fontWeight: "bold" }}> "Confirm" </span> to approve the ticket {serviceTicketById.id}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} variant="contained">
            Cancel
          </Button>
          <Button sx={{ height: "self" }} variant="contained" onClick={() => onPressConfirmApprove()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showDialogAction} onClose={() => setShowDialogAction(false)}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              setShowDialogAction(false);
              setShowDialogVendor(false);
              setSelectedVendor("");
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {showDialogVendor ? null : (
            <Typography mb={2}>
              {(serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED ||
                serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED ||
                serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_REQUIRED) && (
                <>
                  - Choosing <span style={{ fontWeight: "bold" }}>'Yes'</span> will confirm the technician's recommendation and will set the
                  ticket status to{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED
                      ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED_PENDING
                      : serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED
                      ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED_PENDING
                      : serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED
                      ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_PENDING
                      : null}
                  </span>
                  .<br></br>
                  <br></br>- Choosing <span style={{ fontWeight: "bold" }}>'No'</span> will set the ticket status to
                  <span style={{ fontWeight: "bold" }}>'Assigned'</span>.
                </>
              )}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {showDialogVendor ? (
            <Stack sx={{ marginLeft: "10px", marginRight: "10px" }}>
              <Typography sx={{ paddingBottom: "20px" }}>
                Selecting the vendor will mark the ticket's status as "Assigned". If no vendor is selected, the ticket status will be
                'Vendor Support Pending'.
              </Typography>
              <Autocomplete
                id="vendor-select"
                options={appConstants.customers
                  .filter((user) => user.customerType === appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR)
                  .map((item) => item.customerName)}
                value={selectedVendor}
                onChange={(event, newValue) => setSelectedVendor(newValue)}
                sx={{ minWidth: 300, maxWidth: 600, paddingBottom: "45px" }}
                renderInput={(params) => <TextField {...params} label="Vendor" />}
              />
              <DialogActions>
                <Button variant="contained" onClick={() => setShowDialogVendor(false)}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => onPressConfirmApproveAction("vendor")}>
                  Confirm
                </Button>
              </DialogActions>
            </Stack>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setShowDialogAction(false);
                  onPressConfirmApproveAction("assigned");
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_REQUIRED) {
                    setShowDialogVendor(true);
                  } else {
                    onPressConfirmApproveAction("spares");
                  }
                }}
              >
                Yes
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Stack sx={{ mr: 5, mb: 1, pl: 2 }}>
        <Stack sx={{ mb: 1 }}>
          <Card variant="outlined" sx={{ p: 3 }}>
            {editTicketEnabled ? (
              <CreateUpdateTicket
                initialFormData={ticketId ? { ...serviceTicketById, machineInfo } : null}
                setEditTicketEnabled={setEditTicketEnabled}
              />
            ) : (
              ticketDetails()
            )}
          </Card>
        </Stack>
        {ticketId && (
          <Stack flexDirection="row" sx={{ flex: 1, gap: 1, flexWrap: "wrap" }}>
            <Stack sx={{ flex: 1, minWidth: 300 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <TicketNotes ticketId={ticketId} />
              </Card>
            </Stack>
            <Stack sx={{ flex: 1, minWidth: 300 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <Timelogs ticketId={ticketId} />
              </Card>
            </Stack>
            <Stack sx={{ flex: 1, minWidth: 300, maxWidth: 600 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <Gallery ticketId={ticketId} />
              </Card>
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default ServiceTicket;
