import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  OutlinedInput,
  IconButton,
  InputAdornment,
  Tooltip,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
  Stack,
  Autocomplete,
  TextField,
  useTheme,
  Button,
  CircularProgress,
} from "@mui/material";
import { Search, SignalCellularNullTwoTone } from "@mui/icons-material";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";

import Header from "component/Header";
import { engineerReportsById, readAsyncStorageValues } from "features/service/service.slice";
import Service from "features/service/service";
import "../../App.css";
import "../../stylesheet/engReports.css";
import DataGridTable from "component/DataGridTable";
import { Links } from "utils/pageLinkNames.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Reports = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const [engineerId, setEngineerId] = React.useState("");
  const dispatch = useDispatch();
  const [serviceTickets, setServiceTickets] = useState([]);
  const [date, setDate] = useState(null);

  const { engineerReports, appConstants } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const engineerReportsData = async (userId) => {
    dispatch(engineerReportsById(userId));
  };

  const onChange = (event) => {
    const value = event.target.value;
    setEngineerId(value);
    engineerReportsData(value);
  };

  useEffect(() => {
    if (engineerReports) {
      const modified = engineerReports.map((ticket) => {
        return {
          ...ticket,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
          dateCompleted: moment(ticket.dateCompleted).format("DD MMM YYYY"),
        };
      });
      setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
    }
  }, [engineerReports]);

  useEffect(() => {
    dispatch(engineerReportsById());
  }, []);

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const gridReportsAction = (args) => {
    return (
      <Link to={`/${Links.REPORTS}/` + args.row.tktId}>
        <IconButton sx={{ mr: 1 }}>
          <Tooltip title="View report">
            <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      </Link>
    );
  };
  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

  const maintenanceReport = async () => {
    navigate(`${Links.MAINTENANCE_REPORT}`, {
      state: { date: date ? moment(date).format("YYYY-MM-DD") : moment(new Date()).subtract("1", "days").format("YYYY-MM-DD") },
    });
  };

  const reportGrid = [
    {
      field: "dateCreated",
      headerName: "Created",
      minWidth: 50,
      flex: 0.3,
      sortComparator: dateComparator,
    },
    {
      field: "tktId",
      headerName: "Ticket",
      minWidth: 30,
      flex: 0.2,
    },
    {
      field: "dateCompleted",
      headerName: "Completed",
      minWidth: 50,
      flex: 0.3,
      sortComparator: dateComparator,
    },
    {
      field: "maintenanceCode",
      headerName: "Maintenance code",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "serialNo",
      headerName: "Serial Number",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "machineModel",
      headerName: "Model",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "ticketStatus",
      headerName: "Status",
      minWidth: 60,
      flex: 0.3,
    },
    {
      headerName: "",
      minWidth: 60,
      flex: 0.35,
      sortable: false,
      filterable: false,
      renderCell: gridReportsAction,
    },
  ];
  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Reports"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />
          <Autocomplete
            onChange={onChange}
            size="small"
            value={engineerId ? appConstants?.appUsers.find((user) => user.userId === engineerId) : null}
            options={
              Object.keys(appConstants).length
                ? appConstants?.appUsers
                    .filter((obj) => {
                      return obj.userRole === appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN;
                    })
                    .sort((a, b) => {
                      return a.name > b.name ? 1 : -1;
                    })
                : []
            }
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Select techincian" />}
            sx={{ width: 300 }}
            renderOption={(props, option) => (
              <MenuItem {...props} key={option.userId} value={option.userId}>
                {option.name}
              </MenuItem>
            )}
          />
          <Button
            // startIcon={emailSending ? <CircularProgress size={12} /> : <SendIcon />}
            sx={{ height: "self", textTransform: "none" }}
            variant="contained"
            onClick={() => maintenanceReport()}
          >
            Maintenance Report
          </Button>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              maxDate={moment()}
              label="Select date"
              format={"DD MMM YYYY"}
              value={date ? moment(date) : null}
              onChange={(newValue) => setDate(newValue)}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Stack>
        <DataGridTable
          rowId={"tktId"}
          data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={reportGrid}
        />
      </Box>
    </div>
  );
};
export default Reports;
