import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link } from "react-router-dom";
import { Paper, Box, Stack, Typography, Button, Tooltip, IconButton, useTheme, CircularProgress } from "@mui/material";
import { ZoomIn as ViewIcon } from "@mui/icons-material";
import moment from "moment";

import Header from "component/Header";
import DataGridTable from "component/DataGridTable";

import { getAllUsers } from "features/appUsers/appUsers.slice";
import { readAsyncStorageValues, getServiceTicketsByStatusList } from "features/service/service.slice";
import AddEditUser from "../AddEditUser";
import { Links } from "utils/pageLinkNames.js";

const User = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const theme = useTheme();

  const [initialFormData, setInitialFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    department: "",
    userRole: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const [serviceTickets, setServiceTickets] = useState([]);

  const { appConstants, serviceTicketsByStatusList, isLoading } = useSelector((state) => state.service);
  const { allUsers } = useSelector((state) => state.appUsers);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (id && serviceTicketsByStatusList) {
      const info = allUsers.find((user) => user.userId == id);
      const modified = serviceTicketsByStatusList.map((ticket) => {
        return {
          ...ticket,
          ticketId: ticket.id,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
        };
      });
      switch (info.userRole) {
        case appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN:
          setServiceTickets(
            modified
              ?.filter(
                (item) =>
                  item.assignees.find((assignee) => parseInt(assignee.id) == parseInt(id)) || parseInt(item.assigner.id) == parseInt(id)
              )
              .sort((a, b) => b.ticketId - a.ticketId) || []
          );
          break;
        case appConstants.allTypes.USER_ROLES.ROLE_OPERATOR:
          setServiceTickets(
            modified?.filter((item) => parseInt(item.assigner.id) == parseInt(id)).sort((a, b) => b.ticketId - a.ticketId) || []
          );
          break;
        case appConstants.allTypes.USER_ROLES.ROLE_FINANCE:
          setServiceTickets(
            modified?.filter((item) => parseInt(item.assigner.id) == parseInt(id)).sort((a, b) => b.ticketId - a.ticketId) || []
          );
          break;
        default:
          setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
          break;
      }
    }
  }, [serviceTicketsByStatusList]);

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length) {
      dispatch(
        getServiceTicketsByStatusList({
          statusList: [
            appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.CANCELLED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.ON_HOLD,
            appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
            appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED_PENDING,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED_PENDING,
            appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_PENDING,
            appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
            appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
          ],
        })
      );
    }
  }, [dispatch, appConstants]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAllUsers());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.name]);

  useEffect(() => {
    if (id && allUsers.length && Object.keys(appConstants).length) {
      const info = allUsers.find((user) => user.userId == id);
      setInitialFormData({ ...info, mobileNumber: info.userMobileNumber, password: "", confirmPassword: "" });
    }
  }, [appConstants, allUsers]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const gridTicketEdit = (args) => {
    return (
      <div>
        {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
          <Link to={`/${Links.SERVICE_TICKET}/` + args.row.ticketId}>
            <IconButton sx={{ mr: 1 }}>
              <Tooltip title="View">
                <ViewIcon sx={{ color: theme.palette.primary[50] }} />
              </Tooltip>
            </IconButton>
          </Link>
        ) : null}
      </div>
    );
  };
  const serviceTicketForMachineTableAttributes = [
    {
      field: "dateCreated",
      headerName: "Created",
      minWidth: 60,
      flex: 0.3,
      sortComparator: dateComparator,
    },
    {
      field: "ticketId",
      headerName: "Ticket Id",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 60,
      flex: 0.3,
      sortable: false,
      filterable: false,
      renderCell: gridTicketEdit,
    },
  ];

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["User", id ? `${initialFormData.name}  [${initialFormData.userName}]` : ""]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditUser initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 3 }}>
            <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
              <Stack rowGap={1}>
                <Typography>Name : {initialFormData.name}</Typography>
                <Typography>User name : {initialFormData.userName}</Typography>
                <Typography>Role : {initialFormData.userRole}</Typography>
                <Typography>Active : {initialFormData.enabled ? "Yes" : "No"}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Typography>Email : {initialFormData.email}</Typography>
                <Typography>Mobile number : {initialFormData.mobileNumber}</Typography>
                <Typography>Department : {initialFormData.department}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Tooltip title="Edit asset">
                  <Button onClick={() => setEnableEditing(true)} variant="contained">
                    Edit
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        )}
        {id ? (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5" fontWeight={600} mb={1}>
              Tickets
            </Typography>
            {isLoading ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              <DataGridTable rowId={"ticketId"} data={serviceTickets} columnAttributes={serviceTicketForMachineTableAttributes} />
            )}
          </Paper>
        ) : null}
      </Box>
    </div>
  );
};

export default User;
